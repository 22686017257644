import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const flightAPI = createApi({
	reducerPath: 'flight-api',
	tagTypes: ['Flights', 'Flight'],
	baseQuery,
	endpoints: (build) => ({
		getFlights: build.query<any, any>({
			query: (params) => ({
				url: `/flights/`,
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Flights'],
		}),
		getFlight: build.query<any, any>({
			query: (id) => ({
				url: `/flights/${id}`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['Flight'],
		}),
		createFlight: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: `/flights/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Flights'],
		}),
		flightLandingPermit: build.mutation<any, any>({
			query: ({ body, id }) => ({
				method: 'PATCH',
				url: `/flights-landing-permit/${id}/`,
				body,
				params: {
                    as: 'admin',
				},
			}),
			invalidatesTags: () => ['Flights', 'Flight'],
		}),
	}),
})

export const { useGetFlightsQuery, useCreateFlightMutation, useFlightLandingPermitMutation } = flightAPI
