import { useGetDronesQuery } from 'entities/drone/api'
import { FC, useState } from 'react'
import { useDebounce } from 'shared/hooks'
import Select from '../../../../shared/components/select'

const renderValue = (value:any) => value ? `${value?.title} (${value?.registration_number})` : ''

const SelectDrone: FC<IProps> = (p) => {
	const { ...otherProps } = p

	const [query, setQuery] = useState<string>('')
	const search = useDebounce(query, 500)
	const { data: dronesData } = useGetDronesQuery({
		drone_status: 'ready_for_takeoff',
		search,
	})
	const drones = dronesData?.results ?? []

  return <Select setQuery={setQuery} query={query} empty="Свободного дрона нет" displayValue={renderValue} filteredField={(value) => value?.title ?? ''} renderOption={renderValue} list={drones} {...otherProps} />
}

interface IProps {
	label?: string
	value: any
	onChange: (value: any) => void
	error?: any
	placeholder: string
	disabled?: boolean
}
export default SelectDrone
