import {useEffect} from "react";
import {toast} from 'react-hot-toast'
import {useActions} from "./index";

interface IProps {
    result: any,
    success?: (result: any) => void
    error?: (result: any) => void
}


const useResult = (p: IProps) => {
    const {success, result, error} = p
    const {setLoading} = useActions()

    useEffect(() => {
        if (result.isSuccess) {
            setLoading(false)
            if (success) {
                success(result)
            }

        }
        if (result.isError) {

            const errors: any = result?.error?.data?.errors ?? [{detail: 'Неизвестная ошибка'}]

            errors.forEach((e: any) => toast.error(e.detail));
            setLoading(false)

            if (error) {
                error(result)
            }
        }
    }, [result])

    return null
}
export default useResult