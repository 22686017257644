import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SellerCreateForm, useGetSellerQuery, useSellerEditMutation } from 'entities/seller'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { toast } from 'react-hot-toast'

const SellerEditPage: FC<IProps> = () => {
	const { sellerId } = useParams()

	const { data: sellerData, isLoading } = useGetSellerQuery(sellerId)
	const [sellerEdit, result] = useSellerEditMutation()
	const [defaultValues, setDefaultValues] = useState(null)

	const seller = sellerData

	useEffect(() => {
		if (seller) setSellerValues()
	}, [seller])

	const setSellerValues = async () => {
		const getDefaultValues = {
			...seller,
			avatar: seller?.avatar?.image,
		}
		setDefaultValues(getDefaultValues)
	}

	const sellerEditHandler = async (data: any) => {
		const res = sellerEdit({
			body: {
				name: data.name,
				photo_id: data.photo_id,
				restaurant_admin: data.restaurant_admin.id,
				delivery_zone: data.delivery_zone.id,
			},
			id: sellerId,
		}).unwrap()

		toast.promise(res, {
			loading: `Cохранение...`,
			success: <b>Ресторан редоктирован</b>,
			error: <b>Ошибка</b>,
		})
	}

	return (
		<SellerProvider>
			<LayoutPage
				label="Редактирование ресторана"
				classNameMain={styles.main}
				main={
					!defaultValues || isLoading ? (
						<div>ЗАГРУЗКА...</div>
					) : (
						<SellerCreateForm
							buttonLabel="Сохранить"
							defaultValues={defaultValues}
							result={result}
							seller={sellerEditHandler}
						/>
					)
				}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default SellerEditPage
