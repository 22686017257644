import { usePaymentOrderMutation } from 'entities/order'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { CellPathname } from 'shared/components'
import { useActions, useRecordContext,useResult } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import styles from './styles.module.scss'
import {useEditFeedBackMutation} from "../../../../entities/feedback";

const statuses = [
	{
		id: 1,

    value: "processed",
    name: 'Обработан',
		color: 'bg-green-600/5 dark:bg-green-600/10 text-green-600 border border-green-600/20 dark:border-green-600/20',
	},
	{
		id: 1,
    value: 'not_processed',
    name: 'Не обработан',
		color: 'bg-red-600/5 dark:bg-red-600/10 text-red-600 border border-red-600/20 dark:border-red-600/20',
	},
]

const FeedBackStatus: React.FC<IProps> = (p) => {
	const { field } = p
	const tableRow = useRecordContext()
	const { setLoading } = useActions()
  const [editFeedBack, result] = useEditFeedBackMutation()

  useResult({result})



	if (!tableRow?.headItem) return null


	const feadBackId = getPropertyValue(tableRow?.row, field ?? 'id')
  const feadBackStatus = getPropertyValue(tableRow?.row, field ?? 'status')

  const color = statuses?.find((paidStatus) => paidStatus?.value === feadBackStatus)?.color

	const onChangeFeadBackStatus = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		try {
			setLoading(true)
			const value = event.target.value
      await editFeedBack({ id: feadBackId, body: { status: value } }).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}


	return (
		<CellPathname>
      <select onChange={onChangeFeadBackStatus} value={feadBackStatus} className={[styles.select, color].join(' ')}>
				{statuses.map((status: any) => (
					<option key={status.id} value={status.value}>
						{status.name}
					</option>
				))}
			</select>
		</CellPathname>
	)
}

interface IProps {
	field?: string
}
export default FeedBackStatus
