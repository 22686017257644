import { store } from 'app/store'
import { FC, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { IsLoading } from 'shared/components'

const StoreProvider: FC<IProps> = (p) => {
	const { children } = p

	return (
		<Provider store={store}>
			<IsLoading />
			{children}
		</Provider>
	)
}

interface IProps {
	children: ReactNode
}

export default StoreProvider
