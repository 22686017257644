import { FC, useState, Fragment } from 'react'
import InputField from '../input-field'
import styles from './styles.module.scss'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const Select: FC<IProps> = (p) => {
	const { error, label, placeholder, list, value, onChange, renderOption, displayValue, query, setQuery, disabled, empty = 'Ничего не найдено' } = p

	// const filteredlist = query === '' ? list : list.filter((person: any) => filteredField(person).toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

	return (
		<InputField error={error?.message} label={label}>
			<div className={styles.select}>
				<Combobox nullable disabled={disabled} value={value} onChange={onChange}>
					<div className="relative">
						<div
							className="relative h-10 w-full 
						border border-gray-100 dark:border-gray-700
						rounded-lg  cursor-default  rounded-lg bg-white
						dark:bg-gray-800 text-left focus:outline-none
						focus-visible:ring-2 focus-visible:ring-white
						focus-visible:ring-opacity-75 focus-visible:ring-offset-0
						focus-visible:ring-offset-primary-300"
						>
							<Combobox.Input
								placeholder={placeholder}
								className="w-full h-full  border-none py-2 rounded-lg pl-3 pr-10  leading-5 text-gray-900 dark:text-gray-100 focus:ring-0  focus:outline-none focus:ring-primary-400 focus:border-primary-400 focus:ring-2 focus:ring-offset-0 bg-transparent"
								displayValue={displayValue}
								onChange={(event) => setQuery(event.target.value)}
							/>

							<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</Combobox.Button>
						</div>
						<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
							<Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
								{list.length === 0 ? (
									<div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-gray-400">{empty}</div>
								) : (
									list.map((person: any) => (
										<Combobox.Option key={person.id} className={({ active }) => `relative cursor-pointer select-none  py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900 dark:text-gray-100'}`} value={person}>
											{({ selected, active }) => (
												<>
													<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{renderOption(person)}</span>
													{selected ? (
														<span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary'}`}>
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													) : null}
												</>
											)}
										</Combobox.Option>
									))
								)}
							</Combobox.Options>
						</Transition>
					</div>
				</Combobox>
			</div>
		</InputField>
	)
}

interface IProps {
	label?: string
	value: any
	disabled?: boolean
	onChange: (value: any) => void
	error?: any
	list: any[]
	placeholder: string
	empty?: string
	query?: string

	renderOption: (value: any) => any
	setQuery: (value: string) => any
	filteredField: (value: any) => any
	displayValue: (value: any) => any
}
export default Select
