import { FC, memo, useState } from 'react'
import { Table, CellTitle, CellUser, CellText, CellDate } from 'shared/components'
import { ESort, IFilter, IHead } from 'shared/components/table/types'
import { genId } from 'shared/lib'
import styles from './styles.module.scss'
import { useGetFeedBacksQuery } from '../../../../entities/feedback'
import { FeedBackStatus } from '../index'

// TODO: refactor

const head: IHead = {
	id: genId(),
	rowClick: 'show',
	headClassName: 'h-auto',
	resource: 'feedback',
	bodyClassName: 'items-center flex ',
	list: [
		{
			id: genId(),
			isSort: true,
			sort_field: 'id',
			label: 'ID',
			field: 'id',
			className: 'w-20',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'email',
			label: 'Почта',
			field: 'email',
			className: 'flex-1',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'user_comment',
			label: 'Комментарий',
			field: 'user_comment',
			className: 'flex-1',
			render: () => <CellText />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'admin_comment',
			label: 'Ответ',
			field: 'admin_comment',
			className: 'flex-1',
			render: () => <CellText />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'status',
			label: 'Статус',
			field: 'status',
			className: 'w-48',
			render: () => <FeedBackStatus />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'created_at',
			label: 'Дата создания',
			field: 'created_at',
			className: 'w-52',
			render: () => <CellDate />,
		},
	],
}

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {
	field: 'id',
	sort: ESort.ASC,
}

const FeedBackTable: FC<IProps> = memo(() => {
	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const params = {
		page: filter.pagination.current_page,
		page_size: filter.pagination.per_page,
		ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
	}

	const { data: feedbacks, isLoading, isFetching, isError } = useGetFeedBacksQuery(params)

	return (
		<div className={styles.orderTable}>
			<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} data={feedbacks} />
		</div>
	)
})

interface IProps {}

FeedBackTable.displayName = 'FeedBackTable'
export default FeedBackTable
