import { Link, useLocation } from 'react-router-dom'

const CellPathname: React.FC<IProps> = ({ children }) => {
	const { pathname } = useLocation()
	return <Link to={pathname}>{children}</Link>
}

interface IProps {
	children: React.ReactNode
}
export default CellPathname
