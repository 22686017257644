import styles from './styles.module.scss'
import {Input,Button} from "../../../../shared/components";
import {useEditFeedBackMutation} from "../../api";
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useEffect} from "react";
import {useActions, useRecordContext, useResult} from "../../../../shared/hooks";


interface FormData {
    text: string

}


const schema = yup
	.object({
    text: yup.string().required('Введите текст ответа'),
  })
	.required()




const FeedBackInput: React.FC<IProps> = () => {
    const feedBackRow = useRecordContext()
  const feddback = feedBackRow?.row
  const [editFeedBack,result]=  useEditFeedBackMutation()
  const {setLoading} = useActions()
  useResult({result})

  const {
        register,

    handleSubmit,
    setFocus,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {

    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
      setFocus('text')
  }, [])

  const onSubmit = async (data: FormData) => {
      reset()
        try {
            setLoading(true)

          const feedBackData = {


                id: feddback.id,
            body:{
                admin_comment:data.text,
            }
          }

          await editFeedBack(feedBackData)
        } catch (error) {
            setLoading(false)
        }
    }


  return <form onSubmit={handleSubmit(onSubmit)} className={styles['feedback-input']}>




      <Input  placeholder="Ответ админа" error={errors?.text} {...register('text')} as="textarea"  />

    <Button>Ответить</Button>
    </form>
}

interface IProps {

}
export default FeedBackInput
