import { FC } from 'react'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/24/outline'
import ButtonIcon from '../button-icon'
import { useRecordContext, useResourceContext } from 'shared/hooks'

// TODO: memo

const ButtonShow: FC<IProps> = () => {
	const resourceContext = useResourceContext()
	const recordContext = useRecordContext()

	const to = `/${resourceContext?.resource}/${recordContext?.row.id}/show`
	return (
		<Link to={to}>
			<ButtonIcon>
				<EyeIcon />
			</ButtonIcon>
		</Link>
	)
}

interface IProps {}
export default ButtonShow
