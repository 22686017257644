import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const productAPI = createApi({
	reducerPath: 'product-api',
	tagTypes: ['Product', 'Products', 'Product-Profiles', 'Product-Profile'],
	baseQuery,
	endpoints: (build) => ({
		getProducts: build.query<any, any>({
			query: (params) => ({
				url: `/products/`,
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Products'],
		}),
		getProductProfiles: build.query<any, any>({
			query: (params) => ({
				url: `/product-profiles/`,
				params: {
					as: 'admin',
					...params,
				},
			}),
			providesTags: () => ['Product-Profiles'],
		}),
		getMeasureLength: build.query<any, any>({
			query: (id) => ({
				url: `/measure_length/`,
				params: {
					as: 'admin',
				},
			}),
		}),
		getMeasureWeight: build.query<any, any>({
			query: (id) => ({
				url: `/measure_weight/`,
				params: {
					as: 'admin',
				},
			}),
		}),
		getProductProfile: build.query<any, any>({
			query: (id) => ({
				url: `/product-profiles/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['Product-Profile'],
		}),
		getProduct: build.query<any, any>({
			query: (id) => ({
				url: `/products/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['Product'],
		}),
		productCreate: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: '/products/',
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Products'],
		}),
		productProfileCreate: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: '/product-profiles/',
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Product-Profiles', 'Product'],
		}),
		productEdit: build.mutation<any, any>({
			query: ({ body, id }) => ({
				method: 'PATCH',
				url: `/products/${id}/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Products'],
		}),

		productDelete: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/products/${id}/`,
				body: {},
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Products'],
		}),
		productProfileDelete: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/product-profiles/${id}/`,
				body: {},
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Product-Profiles', 'Product'],
		}),
		productProfileEdit: build.mutation<any, any>({
			query: ({ id, body }) => ({
				method: 'PATCH',
				url: `/product-profiles/${id}/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: ['Product-Profiles', 'Product'],
		}),
	}),
})

export const {
	useGetMeasureLengthQuery,
	useGetMeasureWeightQuery,
	useGetProductsQuery,
	useGetProductQuery,
	useGetProductProfilesQuery,
	useGetProductProfileQuery,
	useProductCreateMutation,
	useProductProfileCreateMutation,
	useProductEditMutation,
	useProductDeleteMutation,
	useProductProfileDeleteMutation,
	useProductProfileEditMutation,
} = productAPI
