import moment from 'moment'
import { useRecordContext } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'

import {CellEmpty} from "shared/components";


const CellDate: React.FC<IProps> = () => {
	const tableRow = useRecordContext()
	if (!tableRow?.headItem) return null
	const date = getPropertyValue(tableRow?.row, tableRow?.headItem.field)

  if(!date) return <CellEmpty/>

	return <div>{moment(date).format('LLL')}</div>
}

interface IProps {}
export default CellDate
