export enum EAuthType {
	KEYCLOCK = 'KEYCLOCK',
	DEFAULT = 'DEFAULT',
}

export interface ILoginData {
	access: string
	refresh: string
}
export interface ILoginFormData {
	phone: string
	password: string
}
