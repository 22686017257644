import {useEidtDroneMutation} from 'entities/drone/api'
import {getDroneStatuses} from 'entities/drone'
import {useSelector} from 'react-redux'
import {CellPathname} from 'shared/components'
import {useActions, useRecordContext, useResult} from 'shared/hooks'
import {getPropertyValue} from 'shared/lib'
import styles from './styles.module.scss'

const CellDroneStatus: React.FC<IProps> = (p) => {
    const {field} = p

    const statuses: any[] = useSelector(getDroneStatuses)
    const tableRow = useRecordContext()
    const {setLoading} = useActions()

    const [editDrone, result] = useEidtDroneMutation()
    useResult({result})


    const getField = field ?? tableRow?.headItem?.field
    const drone = getField ? getPropertyValue(tableRow?.row, field ?? tableRow?.headItem?.field) : tableRow?.row
    const isDelivered = tableRow?.row?.status === 'delivered'


    if (!drone || isDelivered) return <>-</>

    const title = getPropertyValue(drone, 'drone_status')
    const id = getPropertyValue(drone, 'id')


    const color = statuses.find((status: any) => status.label === title)?.color

    const onChangeOrderStatus = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setLoading(true)
            const value = event.target.value
            await editDrone({id: id, body: {drone_status: value}}).unwrap()
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <CellPathname>
            <select onChange={onChangeOrderStatus} value={title} className={[styles.select, color].join(' ')}>
                {statuses.map((status: any) => (
                    <option key={status.id} value={status.label}>
                        {status.name}
                    </option>
                ))}
            </select>
        </CellPathname>
    )
}

interface IProps {
    field?: string
}

export default CellDroneStatus
