import { useGetDeliveryZonesQuery } from 'entities/delivery-zone'
import { FC, useState } from 'react'
import { useDebounce } from 'shared/hooks'
import Select from '../select'

const SelectDeliveryZone: FC<IProps> = (p) => {
	const { ...otherProps } = p

	const [query, setQuery] = useState<string>('')
	const search = useDebounce(query, 500)
	const { data: deliveryZonesData } = useGetDeliveryZonesQuery({ search })
	const deliveryZones = deliveryZonesData?.results ?? []

	return <Select query={query} setQuery={setQuery} displayValue={(value) => value?.address ?? ''} filteredField={(value) => value?.address ?? ''} renderOption={(value) => <>{value?.address ?? ''}</>} list={deliveryZones} {...otherProps} />
}

interface IProps {
	label?: string
	value: any
	onChange: (value: any) => void
	error?: any
	placeholder: string
}
export default SelectDeliveryZone
