import { AppDispatch } from 'app/store'
import { interfaceActions } from 'app/store/interface'
import { orderActions } from 'entities/order'
import { userActions } from 'entities/user/model/slice'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

const allActions = { ...interfaceActions, ...userActions,...orderActions }

const useActions = () => {
	const dispatch = useDispatch<AppDispatch>()
	return bindActionCreators(allActions, dispatch)
}

export default useActions
