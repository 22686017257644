import { FC, ReactNode, useEffect } from 'react'
import { ResourceContext } from 'shared/contexts'
import { useActions } from 'shared/hooks'
import { useProductProfileDeleteMutation } from 'entities/product/api/service'

// TODO: refactor

const ProductProfileProvider: FC<IProps> = (p) => {
	const { children, productId } = p
	const { setLoading } = useActions()

	const [productProfileDelete, deleteResult] = useProductProfileDeleteMutation()

	useEffect(() => {
		if (deleteResult.isError) {
			setLoading(false)
		}
		if (deleteResult.isSuccess) {
			setLoading(false)
		}
	}, [deleteResult])

	const deleteProductProfileHandler = async (productProfile: any) => {
		setLoading(true)
		await productProfileDelete(productProfile.id).unwrap()
	}

	return (
		<ResourceContext.Provider
			value={{
				resource: `product/${productId}/product-profile`,
				onDelete: deleteProductProfileHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
	productId?: Id
}
export default ProductProfileProvider
