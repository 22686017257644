export enum paths {
	INDEX = '/',

	AUTH_KEYCLOCK = '/keyclock',
	AUTH_LOGIN = '/login',

	PRODUCTS_LIST = '/product',
	PRODUCT_CREATE = '/product/create',
	PRODUCT_EDIT = '/product/:productId/edit',
	PRODUCT_SHOW = '/product/:productId/show',
	PRODUCT_PROFILE_CREATE = '/product/:productId/product-profile/create',
	PRODUCT_PROFILE_EDIT = '/product/:productId/product-profile/:productProfileId/edit',
	PRODUCT_PROFILE_SHOW = '/product/:productId/product-profile/:productProfileId/show',

	SELLERS_LIST = '/seller',
	SELLERS_CREATE = '/seller/create',
	SELLERS_EDIT = '/seller/:sellerId/edit',
	SELLER_GROUP_CREATE = '/seller/:sellerId/product-group/create',
	SELLER_GROUP_EDIT = '/seller/:sellerId/product-group/:groupId/edit',
	SELLERS_SHOW = '/seller/:sellerId/show',

	ORDER_LIST = '/order',
	ORDER_CREATE = '/order/create',
	ORDER_EDIT = '/order/:sellerId/edit',
	ORDER_SHOW = '/order/:orderId/show',

	CUSTOMER_LIST = '/customer',
	CUSTOMER_SHOW = '/customer/:customerId',

	DRONE_LIST = '/drone',
	DRONE_CREATE = '/drone/create',
	DRONE_EDIT = '/drone/:droneId/edit',
	DRONE_SHOW = '/drone/:droneId/show',

	DELIVERY_ZONE_LIST = '/delivery-zone',
	DELIVERY_ZONE_CREATE = '/delivery-zone/create',
	DELIVERY_ZONE_EDIT = '/delivery-zone/:deliveryZoneId/edit',
	DELIVERY_ZONE_SHOW = '/delivery-zone/:deliveryZoneId/show',

	FLIGHT_LIST = '/flight',
	FLIGHT_CREATE = '/flight/create',
	FLIGHT_EDIT = '/flight/:flightId/edit',
	FLIGHT_SHOW = '/flight/:flightId/show',

	FEEDBACK_LIST = '/feedback',
	FEEDBACK_SHOW = '/feedback/:feedbackId/show',
}
