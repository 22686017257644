import { FC, memo, useState } from 'react'
import { useRecordContext } from 'shared/hooks'
import { CellPathname } from 'shared/components'

import styles from './styles.module.scss'

// TODO: refactor

const CellText: FC<IProps> = memo(() => {
	const [isShort, setIsShort] = useState(true)
	const tableRow = useRecordContext()

	if (!tableRow?.headItem) return null
	const getText = tableRow?.row[tableRow.headItem.field] ?? ""

	const toggleShort = () => {
		setIsShort(!isShort)
	}

	const shortText = getText.slice(0, 64)
	const text = isShort ? shortText : getText

	const buttonText = isShort ? 'подробнее' : 'cкрыть'

	return (
		<span className={styles.cellText}>
			{text}

			{getText.length > 64 && (
				<CellPathname>
					<button type="button" onClick={toggleShort} className={styles.button}>
						{buttonText}
					</button>
				</CellPathname>
			)}
		</span>
	)
})

interface IProps {}

CellText.displayName = 'CellText'
export default CellText
