import { FC } from 'react'
import { SellerCreateForm, useSellerCreateMutation } from 'entities/seller'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import {useResult} from "../../../shared/hooks";

const SellerCreatePage: FC<IProps> = () => {
	const [createSeller, result] = useSellerCreateMutation()
  useResult({result})

	const sellerCreateHandler = async (data: any) => {
		await createSeller({
			name: data.name,
			photo_id: data.photo_id,
			restaurant_admin: data.restaurant_admin.id,
			delivery_zone: data.delivery_zone.id,
		}).unwrap()
	}

	return (
		<SellerProvider>
			<LayoutPage
				label="Новый ресторан"
				classNameMain={styles.main}
				main={<SellerCreateForm buttonLabel="Создать" result={result} seller={sellerCreateHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default SellerCreatePage
