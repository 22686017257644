/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useActions ,useResult} from 'shared/hooks'
import { SelectUser } from 'shared/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { upload } from 'shared/services/file'
import { paths } from 'app/routes'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import Upload from 'shared/components/upload'
import styles from './styles.module.scss'
import * as yup from 'yup'
import SelectDeliveryZone from 'shared/components/select-delivery-zone'

// TODO: refactor

export const sellerCreateFormSchema = yup
	.object({
		name: yup.string().required('Введите название '),
		photo: yup.mixed().required('Загрузите фото'),
		restaurant_admin: yup.object().required('Выберите администратора ресторана'),
		delivery_zone: yup.object().required('Выберите зону доставки'),
	})
	.required()

interface SellerForm {
	name: string
	restaurant_admin: any
	delivery_zone: any
	photo: any
}

const SellerCreateForm: FC<IProps> = (p) => {
	const { seller, result, defaultValues, buttonLabel } = p
	const navigate = useNavigate()
	const { setLoading } = useActions()

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<SellerForm>({
		defaultValues: {
			name: undefined,
			photo: undefined,
			delivery_zone: undefined,
			restaurant_admin: undefined,
			...defaultValues,
		},
		mode: 'onBlur',
		resolver: yupResolver(sellerCreateFormSchema),
	})

	const successHandler = (result: any) => {
		setLoading(false)
    const data =result.data
		navigate(paths.SELLERS_SHOW.replace(':sellerId', data?.id))
	}

  useResult({result,success:successHandler})



	const onSubmit = async (data: SellerForm) => {
		try {
			setLoading(true)
			const isUrl = typeof data.photo === 'string'
			const photo = isUrl ? undefined : data.photo ? await upload(data.photo, 'image') : undefined

			await seller({
				...data,
				photo_id: photo?.id ?? undefined,
			})
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
			<div className={styles.row}>
				<div className={[styles.row, styles.flexLeft].join(' ')}>
					<Controller control={control} name="photo" render={({ field: { onChange, value } }) => <Upload error={errors?.photo} onChange={onChange} value={value} />} />
				</div>
				<div className={[styles.col, styles.flexRight].join(' ')}>
					<div className={styles.row}>
						<Input label="Название" error={errors?.name} {...register('name')} placeholder="Введите название ресторана" />
					</div>
					<div className={[styles.row].join(' ')}>
						<Controller control={control} name="delivery_zone" render={({ field: { onChange, value } }) => <SelectDeliveryZone value={value} onChange={onChange} label="Зона доставки" placeholder="Выберите зону доставки" error={errors?.delivery_zone} />} />
					</div>
					<div className={[styles.row].join(' ')}>
            <Controller control={control} name="restaurant_admin" render={({ field: { onChange, value } }) => <SelectUser filter={{restaurant_admin__is_active:true}} value={value} onChange={onChange} label="Администратор ресторана" placeholder="Выберите администратора ресторана" error={errors?.restaurant_admin} />} />
					</div>
					<div className={styles.row}>
						<Button type="submit">{buttonLabel}</Button>
					</div>
				</div>
			</div>
		</form>
	)
}

interface IProps {
	result: any
	seller: any
	defaultValues?: any
	buttonLabel: any
}
export default SellerCreateForm
