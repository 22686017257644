import { FC } from 'react'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { DeliveryZoneForm } from 'features/delivery-zone'
import { useCreateDeliveryZoneMutation } from 'entities/delivery-zone'

const DeliveryZoneCreatePage: FC<IProps> = () => {
	const [deliveryZoneCreate, result] = useCreateDeliveryZoneMutation()

	const sellerCreateHandler = async (data: any) => {
		await deliveryZoneCreate({
			title: data.title,
			address: data.address,
			coordinates: data.coordinates,
		}).unwrap()
	}
	return (
		<SellerProvider>
			<LayoutPage
				label="Новая зона доставки"
				classNameMain={styles.main}
				main={
					<DeliveryZoneForm
						buttonLabel="Создать"
						result={result}
						deliveryZone={sellerCreateHandler}
					/>
				}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default DeliveryZoneCreatePage
