export interface IRole {
    id: Id
    value: string,
    name: string

}
export enum ERole  {
    "ADMIN"='admin',
    "CUSTOMER"='customer',
    "DRONE_OPERATOR"='drone_operator',
    "RESTAURANT_ADMIN"='restaurant_admin'
}

export const roles: IRole[] = [
    {id: 1, value: ERole.ADMIN, name: 'Админ'},
    {id: 2, value: ERole.CUSTOMER, name: 'Пользователь'},
    {id: 2, value: ERole.DRONE_OPERATOR, name: 'Оператор дрона'},
    {id: 2, value: ERole.RESTAURANT_ADMIN, name: 'Админ ресторана'},
]

