import 'app/styles/index.scss'
import Providers from './providers'
import { AppRoutes } from './routes'

import { Toaster } from 'react-hot-toast'

const App = () => {
	return (
		<Providers>
			<AppRoutes />
			<Toaster
				toastOptions={{
					className: 'toaster',
				}}
				position="bottom-center"
			/>
		</Providers>
	)
}

export default App
