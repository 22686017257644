import { FlightProdivder, FlightTable } from 'features/flight'
import { ButtonCreate } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const FlightListPage: React.FC<IProps> = () => {
	return (
		<FlightProdivder>
			<LayoutPage
				classNameMain={styles.main}
				label="Полеты"
				main={<FlightTable />}
				right={<>{/* <ButtonCreate /> */}</>}
			/>
		</FlightProdivder>
	)
}

interface IProps {}
export default FlightListPage
