import { FC } from 'react'
import { Link, useMatch, useParams } from 'react-router-dom'
import { ButtonCreate, ButtonDelete, ButtonEdit, Empty } from 'shared/components'
import { RecordContext } from 'shared/contexts'
import styles from './styles.module.scss'

const GroupItem: FC<IGroupItem> = (p) => {
	const { group } = p

	const { sellerId } = useParams()

	const to = `/seller/${sellerId}/show/product-group/${group.id}`
	const math = useMatch({
		path: to,
	})

	return (
		<Link to={to}>
			<li className={[styles.group, math && styles.active].join(' ')}>
				<span>{group.name}</span>
				<div>
					<ButtonEdit />
					<ButtonDelete />
				</div>
			</li>
		</Link>
	)
}
interface IGroupItem {
	group: any
}

const SellerProductGroup: FC<IProps> = (p) => {
	const { groups } = p

	return (
		<div className={styles.sellerProductGroup}>
			<div className={styles.header}>
				<h3 className={styles.title}>Категории</h3>
				<ButtonCreate />
			</div>

			{groups.length === 0 ? (
				<Empty />
			) : (
				<ul className={styles.groups}>
					{groups.map((group: any) => (
						<RecordContext.Provider key={group.id} value={{ row: group }}>
							<GroupItem group={group} />
						</RecordContext.Provider>
					))}
				</ul>
			)}
		</div>
	)
}

interface IProps {
	groups: any[]
}

export default SellerProductGroup
