import { FC, memo } from 'react'
import styles from './styles.module.scss'

// TODO: рефактор проверки на src

const Avatar: FC<IProps> = memo((p) => {
	const { alt, src, className } = p
	const classNames = [styles.avatar, className].join(' ')

	if (!src) return <div className={classNames}>{alt}</div>

	return (
		<div className={classNames}>
			<img src={src} alt={alt} />
		</div>
	)
})

interface IProps {
	alt?: string
	src?: string
	className?: string
}

Avatar.displayName = 'Avatar'
export default Avatar
