import styles from './styles.module.scss'
import {UserView} from "../../../../shared/components";

const FeedBackMessage: React.FC<IProps> = (p) => {
    const {message} = p
  return  <div className={styles['feedback-message']}>
            <div className={styles['feedback-user']}>
                <UserView user={message.user}/>
            </div>
    <div className={[styles['feedback-text'],message.main && styles['feedback-text-main']].join(" ")}>{message.text}</div>
        </div>

}

interface IProps {
    message: {
        user: any
        text: string
        main?: boolean
    }
}

export default FeedBackMessage
