import { useGetSellersQuery } from 'entities/seller'
import { FC, memo, useState } from 'react'
import { Table, ButtonDelete, CellImg, CellTitle, ButtonShow, ButtonEdit, CellUser, CellDeliveryZone, CellSeller } from 'shared/components'
import { IFilter, IHead, ESort } from 'shared/components/table/types'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

// TODO: refactor

const head: IHead = {
	id: genId(),
	headClassName: 'h-auto',
	rowClick: 'show',
	resource: 'product',
	bodyClassName: 'items-center flex ',
	list: [
		{
			id: genId(),
			isSort: true,
			sort_field: 'id',
			label: 'ID',
			field: 'id',
			className: 'w-20',
			render: () => <CellTitle />,
		},

		{
			id: genId(),
			isSort: true,
			sort_field: 'translations__name',
			label: 'Название',
			field: '',
			className: 'flex-1',
			render: () => <CellSeller />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'restaurant_admin',
			label: 'Администратор',
			field: 'restaurant_admin',
			className: 'flex-1',
			render: () => <CellUser />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'delivery_zone',
			label: 'Зона доставки',
			field: 'delivery_zone',
			className: 'flex-1',
			render: () => <CellDeliveryZone />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: '',
			label: '',
			field: '',
			className: 'w-24',
			render: () => (
				<div className="flex items-center gap-1">
					<ButtonEdit />
					<ButtonDelete />
				</div>
			),
		},
	],
}

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {
	field: 'id',
	sort: ESort.ASC,
}

const SellerTable: FC<IProps> = memo(() => {
	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const params = {
		page: filter.pagination.current_page,
		page_size: filter.pagination.per_page,
		ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
	}
	const { data: sellers, isLoading, isFetching, isError } = useGetSellersQuery(params)

	return (
		<div className={styles.productTable}>
			<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} data={sellers} />
		</div>
	)
})

interface IProps {}

SellerTable.displayName = 'SellerTable'
export default SellerTable
