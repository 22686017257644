import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'app/store'
import { initialState } from './initial'

const reducers = {}

const droneSlice = createSlice({
	name: 'drone',
	initialState,
	reducers,
})

export const getDroneStatuses = (state: AppState) => state.drone.statuses

export const droneReducer = droneSlice.reducer
export const droneActions = droneSlice.actions
