import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'app/store'
import { initialState } from './initial'

const reducers = {}

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers,
})

export const getStatuses = (state: AppState) => state.order.statuses

export const orderReducer = orderSlice.reducer
export const orderActions = orderSlice.actions
