import { useGetMeasureWeightQuery } from 'entities/product'
import { FC } from 'react'
import InputField from '../input-field'
import styles from './styles.module.scss'

const SelectMeasureWeight: FC<IProps> = (p) => {
	const { error, label } = p

	const { data: measureWeightData } = useGetMeasureWeightQuery({})

	const measureWeight = measureWeightData?.results ?? []

	return (
		<InputField error={error?.message} label={label}>
			<select className={styles.selectMeasureWeight}>
				{measureWeight.map((item: any) => (
					<option key={item.id} value={item.name}>
						{item.name}
					</option>
				))}
			</select>
		</InputField>
	)
}

interface IProps {
	label: string
	error: any
}
export default SelectMeasureWeight
