import { CustomerProvider } from 'features/customer'
import { CustomerTable } from 'features/customer/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const CustomerListPage: React.FC<IProps> = () => {
	return (
		<CustomerProvider>
			<LayoutPage
				classNameMain={styles.main}
				label="Пользователи"
				main={<CustomerTable />}
				right={<>{/* <ButtonCreate /> */}</>}
			/>
		</CustomerProvider>
	)
}

interface IProps {}
export default CustomerListPage
