import {FC, memo} from 'react'
import {useRecordContext} from 'shared/hooks'
import {getPropertyValue} from 'shared/lib'
import styles from './styles.module.scss'

// TODO: refactor

const CellDeliveryZone: FC<IProps> = memo((p) => {
    const {field, isShowCoordinates} = p

    const tableRow = useRecordContext()

    if (!tableRow?.headItem) return null
    const deliveryZone = getPropertyValue(tableRow?.row, field ?? tableRow?.headItem.field)

    return <div className={styles['delivery-zone']}>
      <div>{deliveryZone.address}</div>
      {isShowCoordinates &&<div className={styles.coordinates}>{deliveryZone.coordinates.latitude}{", "}{deliveryZone.coordinates.longitude}</div>}
    </div>

})

interface IProps {
    left?: any
    field?: string
    isShowCoordinates?: boolean
    render?: (item?: any) => void
}

CellDeliveryZone.displayName = 'CellDeliveryZone'
export default CellDeliveryZone
