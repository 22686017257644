import { FC } from 'react'
import { ProductProfileForm, useProductProfileCreateMutation } from 'entities/product'
import { ProductProfileProvider, ProductProvider } from 'features/product'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'

const ProductProfileCreatePage: FC<IProps> = () => {
	const { productId } = useParams()
	const [productProfileCreate, result] = useProductProfileCreateMutation()

	const productProfileCreateHandler = async (data: any) => {
		await productProfileCreate({
			price: data.price,
			name: data.name,
			quantity_left: data.quantity_left,
			product: productId,
			measure_weight: data.measure_weight,
			measure_length: data.measure_length,
			weight: data.weight,
			size: data.size,
		}).unwrap()
	}

	return (
		<ProductProvider productId={productId}>
			<LayoutPage
				label="Новый профиль продукта"
				classNameMain={styles.main}
				main={<ProductProfileForm buttonLabel="Создать" result={result} product={productProfileCreateHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductProfileCreatePage
