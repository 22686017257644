import { DeliveryZoneProdivder, DeliveryZoneTable } from 'features/delivery-zone'
import { ButtonCreate } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const DeliveryZoneListPage: React.FC<IProps> = () => {
	return (
		<DeliveryZoneProdivder>
			<LayoutPage
				classNameMain={styles.main}
				label="Зоны доставки"
				main={<DeliveryZoneTable />}
				right={
					<>
						<ButtonCreate />
					</>
				}
			/>
		</DeliveryZoneProdivder>
	)
}

interface IProps {}
export default DeliveryZoneListPage
