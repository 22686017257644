import { FC } from 'react'
import { AuthForm } from 'entities/auth'
import styles from './styles.module.scss'

const AuthLogin: FC<IProps> = () => {
	return (
		<div className={styles.auth}>
			<AuthForm />
		</div>
	)
}

interface IProps {}
export default AuthLogin
