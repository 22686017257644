import { useDeleteDroneMutation } from 'entities/drone'
import { FC, ReactNode, useEffect } from 'react'
import { ResourceContext } from 'shared/contexts'
import {useActions, useResult} from 'shared/hooks'

// TODO: refactor

const DroneProvider: FC<IProps> = (p) => {
	const { children } = p

	const { setLoading } = useActions()

	const [droneDelete, result] = useDeleteDroneMutation()
  useResult({result})

	const deleteHandler = async (drone: any) => {
		try {
			setLoading(true)
			await droneDelete(drone.id).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<ResourceContext.Provider
			value={{
				resource: 'drone',
				onDelete: deleteHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
}
export default DroneProvider
