
import { FC ,ReactNode} from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import { useCookies } from 'react-cookie'
import { AUTH_TYPE_KEY, TOKEN_KEY } from 'shared/config'
import { keycloak } from 'shared/services'
import { EAuthType } from 'entities/auth'

// TODO: рефактор и обработать logout

const onLoad = 'check-sso'

const KeycloakProvider: FC<IProps> = (p) => {
	const { children } = p

	const [cookies, setCookie] = useCookies([TOKEN_KEY, AUTH_TYPE_KEY])

	const tokenLogger = (tokens: AuthClientTokens) => {
		try {
			if (tokens?.token) {
				setCookie(AUTH_TYPE_KEY, EAuthType.KEYCLOCK, {})
				setCookie(TOKEN_KEY, tokens?.token, {})
			}
		} catch (error) {}
	}

	return (
		<ReactKeycloakProvider
			onTokens={tokenLogger}
			initOptions={{ onLoad: onLoad, checkLoginIframe: false }}
			authClient={keycloak}
		>
			{children}
		</ReactKeycloakProvider>
	)
}

interface IProps {
	children: ReactNode
}

export default KeycloakProvider
