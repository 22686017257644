import ProductCreateForm from 'entities/product/components/product-form'
import { Avatar } from 'shared/components'
import styles from './styles.module.scss'

const OrderProduct: React.FC<IProps> = (p) => {
	const { product } = p
	return (
		<div className={styles.product}>
			<Avatar className={styles.img} alt={product.product} src={product.photo} />
			<div className={styles.info}>
				<div className={styles.profile}>
					<span>{product.category}</span>
					&sdot;
					<span>{product.product}</span>
					&sdot;
					<span>{product.profile}</span>
				</div>
				<h5 className={styles.name}>{product.product}</h5>
				<div className={styles.details}>
					<span>{product.price}</span>
				</div>
			</div>
			<span className={styles.quantity}>× {product.quantity}</span>
		</div>
	)
}

interface IProps {
	product: any
}
export default OrderProduct
