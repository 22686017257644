import styles from './styles.module.scss'

const LabelRow: React.FC<IProps> = ({ content, label, fieldClassName, rowClassName }) => {
	return (
		<div className={[styles['label-row'], rowClassName].join(' ')}>
			<div className={[styles['label-field'], fieldClassName].join(' ')}>{label}:</div>
			{content}
		</div>
	)
}

interface IProps {
	content: React.ReactNode
	label: string
	fieldClassName?: string
	rowClassName?: string
}
export default LabelRow
