import { CustomerRoles, useGetCustomersQuery } from 'entities/customer'
import { useGetDeliveryZonesQuery } from 'entities/delivery-zone'
import { useGetDronesQuery } from 'entities/drone'
import { CellOrderStatus, useGetOrdersQuery } from 'entities/order'
import { FC, memo, useState } from 'react'
import { Table, CellTitle, CellSeller, CellUser, CellDate, CellImg, ButtonShow, ButtonEdit, ButtonDelete, CellDeliveryZone } from 'shared/components'
import { ESort, IFilter, IHead } from 'shared/components/table/types'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

// TODO: refactor

const head: IHead = {
	id: genId(),
	headClassName: 'h-auto',

	resource: 'order',
	bodyClassName: 'items-center flex ',
	list: [
		{
			id: genId(),
			isSort: true,
			sort_field: 'id',
			label: 'Id',
			field: 'id',
			className: 'w-20',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: true,
			sort_field: 'title',
			label: 'Название',
			field: 'title',
			className: 'flex-1',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'address',
			label: 'Адресс',
			field: '',
			className: 'flex-1',
			render: () => <CellDeliveryZone isShowCoordinates />,
		},

		{
			id: genId(),
			isSort: false,
			sort_field: '',
			label: '',
			field: '',
			className: 'w-20',
			render: () => (
				<div className="flex items-center gap-1">
					{/* <ButtonShow /> */}

					<ButtonDelete />
				</div>
			),
		},
	],
}

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {
	field: 'id',
	sort: ESort.ASC,
}

const DeliveryZoneTable: FC<IProps> = memo(() => {
	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const params = {
		page: filter.pagination.current_page,
		page_size: filter.pagination.per_page,
		ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
	}
	const { data: deliveryZones, isLoading, isFetching, isError } = useGetDeliveryZonesQuery(params)

	return (
		<div className={styles.orderTable}>
			<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} data={deliveryZones} />
		</div>
	)
})

interface IProps {}

DeliveryZoneTable.displayName = 'DeliveryZoneTable'
export default DeliveryZoneTable
