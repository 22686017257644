import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const customerAPI = createApi({
	reducerPath: 'customer-api',
	tagTypes: ['Customers', 'Customer'],
	baseQuery,
	endpoints: (build) => ({
		getCustomers: build.query<any, any>({
			query: (params) => ({
				url: '/accounts/',
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Customers'],
		}),
	}),
})

export const { useGetCustomersQuery } = customerAPI
