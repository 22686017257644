import { FC } from 'react'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { DroneForm } from 'features/drone'
import { useEidtDroneMutation, useGetDroneQuery } from 'entities/drone'
import { useParams } from 'react-router-dom'

const DroneEditPage: FC<IProps> = () => {
	const { droneId } = useParams()

	const { data: droneData, isLoading } = useGetDroneQuery(droneId)
	const [droneEdit, result] = useEidtDroneMutation()

	const drone = droneData
	const defaultValues = {
		...drone,
	}

	const droneEditHandler = async (data: any) => {
		await droneEdit({
			id: droneId,
			body: {
				serial_number: data.serial_number,
				delivery_zone: data.delivery_zone.id,
        title: data.title,
        registration_number: data.registration_number,
			},
		}).unwrap()
	}
	return (
		<SellerProvider>
			<LayoutPage
				label="Новый дрон"
				classNameMain={styles.main}
				main={isLoading ? <div>ЗАГРУЗКА ДРОНА...</div> : <DroneForm defaultValues={defaultValues} buttonLabel="Создать" result={result} drone={droneEditHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default DroneEditPage
