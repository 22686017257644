import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useRecordContext, useResourceContext } from 'shared/hooks'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import ButtonIcon from '../button-icon'

// TODO: memo

const ButtonEdit: FC<IProps> = (p) => {
	const { state } = p
	const resourceContext = useResourceContext()
	const recordContext = useRecordContext()

	const to = `/${resourceContext?.resource}/${recordContext?.row.id}/edit`

	return (
		<Link to={to} state={state}>
			<ButtonIcon>
				<PencilSquareIcon />
			</ButtonIcon>
		</Link>
	)
}

interface IProps {
	state?: any
}
export default ButtonEdit
