import { FC, memo, useState } from 'react'
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { useTheme } from 'shared/hooks'
import ButtonIcon from 'shared/components/button-icon'

// TODO: вынести логику и отрефакторить

const ThemeSwitch: FC<IProps> = memo(() => {
	const { colorTheme, setTheme } = useTheme()
	const [dark, setDark] = useState<boolean>(colorTheme === 'light' ? true : false)

	const toggleDarkMode = (checked: boolean) => {
		setTheme(colorTheme)
		setDark(checked)
	}

	return <ButtonIcon onClick={() => toggleDarkMode(!dark)}>{!dark ? <MoonIcon /> : <SunIcon />}</ButtonIcon>
})

interface IProps {}

ThemeSwitch.displayName = 'ThemeSwitch'
export default ThemeSwitch
