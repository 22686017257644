import { useSellerDeleteMutation } from 'entities/seller'
import { FC, ReactNode, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { ResourceContext } from 'shared/contexts'
import { useActions } from 'shared/hooks'

// TODO: refactor

const SellerProvider: FC<IProps> = (p) => {
	const { children } = p

	const navigate = useNavigate()
	const { setLoading } = useActions()

	const [sellerDelete, deleteResult] = useSellerDeleteMutation()

	useEffect(() => {
		if (deleteResult.isError) {
			setLoading(false)
		}
		if (deleteResult.isSuccess) {
			setLoading(false)
			navigate('/seller')
		}
	}, [deleteResult])

	const deleteHandler = async (seller: any) => {
		try {
			setLoading(true)
			await sellerDelete(seller.id).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<ResourceContext.Provider
			value={{
				resource: 'seller',
				onDelete: deleteHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
}
export default SellerProvider
