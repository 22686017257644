import {FC, useEffect, useState} from 'react'
import styles from './styles.module.scss'
import {ArrowDownCircleIcon,PlusIcon, ExclamationCircleIcon} from '@heroicons/react/24/outline'
import {motion} from 'framer-motion'
import getBase64 from 'shared/lib/getBase64'
import InputField from '../input-field'

// TODO: refactor

const fileTypes = ['image/webp', 'image/jpeg', 'image/png', 'image/jpg', 'image/gif']

const variants = {
    open: {scale: 1, opacity: 1},
    closed: {scale: 0, opacity: 0},
}

const Upload: FC<IProps> = (p) => {
    const {onChange, value, label = 'Фото', error} = p

    const [isDrag, setIsDrag] = useState(false)
    const [photo, setPhoto] = useState<any>(value)
    const [isError, setIsError] = useState(false)

    const getPhoto = async () => {
        const isUrl = typeof value === 'string'
        if (isUrl) return
        setPhoto(null)
        const photoBase64 = await getBase64(value)
        setPhoto(photoBase64)
    }

    useEffect(() => {
        if (value) {
            getPhoto()
        }
    }, [value])

    const onChangeHandler = (file: any) => {
        onChange(file)
        setIsDrag(false)
    }

    const onChangeInputHandler = (event: any) => {
        const file = event.target.files[0]
        const isValidateFiles = validateFiles(file)

        if (!isValidateFiles) {
            setIsDrag(false)
            setIsError(true)
            if (photo) {
                const savePhoto = JSON.parse(JSON.stringify(photo))
                setPhoto(null)
                setTimeout(() => {
                    setPhoto(savePhoto)
                    setIsError(false)
                }, 2000)
            } else {
                setTimeout(() => {
                    setIsError(false)
                }, 2000)
            }

            return
        }
        onChangeHandler(file)
    }

    const validateFiles = (file: any) => {
        return fileTypes.some((type) => type === file.type)
    }

    const onDragOver = (event: any) => {
        event.preventDefault()
        setIsDrag(true)
    }

    const onDragLeave = (event: any) => {
        event.preventDefault()
        setIsError(false)
        setIsDrag(false)
    }

    const onDrop = (event: any) => {
        event.preventDefault()

        const file = event.dataTransfer.files[0]
        const isValidateFiles = validateFiles(file)

        if (!isValidateFiles) {
            setIsDrag(false)
            setIsError(true)
            if (photo) {
                const savePhoto = JSON.parse(JSON.stringify(photo))
                setPhoto(null)
                setTimeout(() => {
                    setPhoto(savePhoto)
                    setIsError(false)
                }, 2000)
            } else {
                setTimeout(() => {
                    setIsError(false)
                }, 2000)
            }

            return
        }
        onChangeHandler(file)
    }

    return (
        <InputField error={error?.message} label={label}>
            <label
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragLeave={onDragLeave}
                className={[styles.container, photo && 'gap-4'].join(' ')}
            >
                <motion.img
                    variants={variants}
                    animate={photo ? (isDrag ? 'closed' : 'open') : 'closed'}
                    src={photo}
                    className={styles.img}
                    alt="2"
                />
                <input hidden type="file" onChange={onChangeInputHandler}/>

                <motion.div
                    variants={variants}
                    animate={photo ? (isDrag ? 'open' : 'closed') : 'open'}
                    className={[styles.upload, isDrag ? styles.active : isError && styles.error].join(' ')}
                >
                    <motion.div
                        variants={variants}
                        animate={!isDrag ? (isError ? 'closed' : 'open') : 'closed'}
                        className={[styles.start, styles.block].join(' ')}
                    >
                        <div className={styles.button}>


                          <PlusIcon className={styles.icon}/>

                            <span className={styles.text}>
                Загрузить фото
              </span>
                        </div>
                        <span className={styles.text}>
              Рекомендуемое разрешение 600x450. Формат - PNG, JPG, JPEG или WEBP
						</span>
                    </motion.div>
                    <motion.div
                        variants={variants}
                        animate={isDrag ? 'open' : 'closed'}
                        className={[styles.isDrag, styles.block].join(' ')}
                    >
                        <ArrowDownCircleIcon className={styles.icon}/>
                        <span className={styles.text}>Отпустите фотографию для загрузки</span>
                    </motion.div>
                    <motion.div
                        variants={variants}
                        animate={isError ? (isDrag ? 'closed' : 'open') : 'closed'}
                        className={[styles.block].join(' ')}
                    >
                        <ExclamationCircleIcon className={styles.icon}/>
                        <span className={styles.text}>Ошибка неверный тип файла и слишком большой файл</span>
                    </motion.div>
                </motion.div>
            </label>
        </InputField>
    )
}

interface IProps {
    value: any
    onChange: any
    error?: any
    label?: string
}

export default Upload
