import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { SellerShowProduct, useGetSellerQuery } from 'entities/seller'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonDelete, ButtonEdit, ButtonList, CellDeliveryZone, CellUser, LabelRow } from 'shared/components'
import { RecordContext } from 'shared/contexts'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

const SellerShowPage: FC<IProps> = () => {
	const { sellerId } = useParams()
	const { data: sellerData, isLoading } = useGetSellerQuery(sellerId)

	if (isLoading)
		return (
			<div className={styles.container}>
				<div>ЗАГРУЗКА...</div>
			</div>
		)

	const seller = sellerData

	return (
		<SellerProvider>
			<RecordContext.Provider
				value={{
					row: seller,
					headItem: {
						id: genId(),
						label: '1',
						field: '1',
						sort_field: '1',
						isSort: false,
						className: '1',
						render: () => <></>,
					},
				}}
			>
				<div className={styles.page}>
					<div className={styles.header}>
						<div className={styles.container}>
							<div className={styles['header-main']}>
								<div className={styles['header-left']}>
									<ButtonBack to="/seller" />
									<div className={styles.info}>
										<img alt={seller.name} className={styles.img} src={seller.photo} />
										<div className={styles['seller-info']}>
											<LabelRow fieldClassName={styles['label-field']} label="Название" content={seller.name} />
											<LabelRow fieldClassName={styles['label-field']} label="Зона доставки" content={<CellDeliveryZone isShowCoordinates field="delivery_zone" />} />
											<LabelRow fieldClassName={styles['label-field']} label="Администратор" content={<CellUser field="restaurant_admin" />} />
										</div>
									</div>
								</div>
								<div className={styles['header-right']}>
									<ButtonEdit />
									<ButtonList />
									<ButtonDelete />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.main}>
						<div className={styles.container}>
							<div className={styles.content}>
								<SellerShowProduct />
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						<div className={styles.container}>
							<div className={styles['footer-main']}></div>
						</div>
					</div>
				</div>
			</RecordContext.Provider>
		</SellerProvider>
	)
}

interface IProps {}
export default SellerShowPage
