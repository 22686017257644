/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Logo, Button, Input} from 'shared/components'
import {AUTH_TYPE_KEY, TOKEN_KEY} from 'shared/config'
import {useActions, useResult} from 'shared/hooks'
import {EAuthType, useLoginMutation} from 'entities/auth'
import {authSchema} from './lib'
import {defaultValues} from './model'
import {IFormData} from './types'
import styles from './styles.module.scss'

// TODO: вывести все сообщение в отдельный файлик
// TODO: отрефакторит компонент винести логику

const AuthForm: FC<IProps> = () => {
    const {setLoading} = useActions()
    const [cookies, setCookie] = useCookies([TOKEN_KEY, AUTH_TYPE_KEY])

    const [login, result] = useLoginMutation()

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm<IFormData>({
        mode: 'onBlur',
        resolver: yupResolver(authSchema),
        defaultValues,
    })

    const successHandler = (result: any) => {
        const data = result.data
        const token = data.access
        const options = {
            // expires,
        }

        setCookie(TOKEN_KEY, token, options)
        setCookie(AUTH_TYPE_KEY, EAuthType.DEFAULT, options)

        setLoading(false)
    }


    const errorHandler = (error: any) => {
        setError('phone', {message: error?.data?.detail ?? error?.status})
        setLoading(false)
    }
    useResult({result, success: successHandler, error: errorHandler})


    const onSubmit = async (data: IFormData) => {
        setLoading(true)
        await login(data).unwrap()
    }

    return (
        <div className={styles.authForm}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.authForm}>
                <Logo/>
                <h3 className={styles.title}>Добро пожаловать 👋</h3>
                <div className={styles.groups}>
                    <Input
                        error={errors?.phone}
                        {...register('phone')}
                        type="tel"
                        placeholder="Номер телефона"
                    />
                    <Input
                        error={errors?.password}
                        {...register('password')}
                        type="password"
                        placeholder="Пароль"
                    />
                </div>
                <Button>Войти</Button>
            </form>
        </div>
    )
}

interface IProps {
}

export default AuthForm
