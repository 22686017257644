const getBase64 = (file: any): any => {
	return new Promise((resolve) => {
		var reader = new FileReader()
		reader.onloadend = function () {
			resolve(reader.result)
		}
		reader.readAsDataURL(file)
	})
}
export default getBase64
