import { ReactNode, Suspense, FC } from 'react'
import { BrowserRouter } from 'react-router-dom'

const RouterProvider: FC<IProps> = (p) => {
	const { children } = p

	return (
		<BrowserRouter>
			<Suspense fallback={<div>withRouter</div>}>{children}</Suspense>
		</BrowserRouter>
	)
}

interface IProps {
	children: ReactNode
}

export default RouterProvider
