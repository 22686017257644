import moment from 'moment'
import {CellOrderStatus, getStatuses, OrderMap, useGetOrderQuery} from 'entities/order'
import OrderProduct from 'entities/order/components/order-product'
import {CellOrderPaid, OrderLandingPoint, OrderProvider} from 'features/order'
import {useParams} from 'react-router-dom'
import {ButtonBack, CellDeliveryZone, CellSeller, CellTitle, CellUser, LabelRow} from 'shared/components'
import {RecordContext} from 'shared/contexts'
import styles from './styles.module.scss'
import {useSelector} from 'react-redux'
import {DroneStatus, SelectDrone} from 'entities/drone'
import {useCreateFlightMutation} from 'entities/flight'
import {useActions} from 'shared/hooks'
import {toast} from 'react-hot-toast'
import {useEffect} from 'react'
import {FlightSimulation} from 'entities/flight/components'
import CellFlightLandingPermit from "../../../features/flight/components/cell-flight-landing-permit";
import { genId } from 'shared/lib'

const OrderShow: React.FC<IProps> = () => {
    const {orderId} = useParams()
    const {setLoading} = useActions()
    const statuses: any[] = useSelector(getStatuses)
    const {data: orderData, isLoading} = useGetOrderQuery(orderId, {pollingInterval: 3000})
    const [createFlight, createFlightResult] = useCreateFlightMutation()

    useEffect(() => {
        if (createFlightResult.isError) {
            const error: any = createFlightResult.error
            toast.error(error?.data?.detail ?? 'Ошибка')
            setLoading(false)
        }
        if (createFlightResult.isSuccess) setLoading(false)
    }, [createFlightResult])

    if (isLoading)
        return (
            <div className={styles.container}>
                <div>ЗАГРУЗКА...</div>
            </div>
        )

    const order = orderData

    const flights = order?.flight
    const flight = flights ? order?.flight[0] : null
    const drone = flight?.drone
    const isDrone = !!drone

    const curStatus = order.status_history[order.status_history.length - 1]
    const isDelivered = curStatus.status === 'delivered'

    const orderWith = {
        ...order,
        status: curStatus.status,
        isDelivered,
        flight,
    }

    const createFlightHandler = (drone: any) => {
        try {
            setLoading(true)
            createFlight({order: Number(orderId), drone: +drone.id})
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <OrderProvider>
            <RecordContext.Provider
                value={{
                    row: orderWith,
                    headItem: {
                        id: genId(),
                        label: '1',
                        field: '1',
                        sort_field: '1',
                        isSort: false,
                        className: '22',
                        render: () => <></>,
                    },
                }}
            >
                <div className={styles.page}>
                    <div className={styles.header}>
                        <div className={styles.container}>
                            <div className={styles['header-content']}>
                                <div className={styles['header-top']}>
                                    <div className={styles['header-left']}>
                                        <ButtonBack to="/order"/>
                                    </div>
                                    <div className={styles['header-right']}></div>
                                </div>
                                <div className={styles['header-main']}>
                                    <div className={[styles['status-history'], styles.info].join(' ')}>
                                        <div
                                            className={[styles['status-history-header'], styles['info-header']].join(' ')}>
                                            <h4>Cтатус</h4>
                                            <CellOrderStatus/>
                                        </div>
                                        <div className={[styles['status-history-main'], styles['info-main']].join(' ')}>
                                            {order?.status_history?.map((status: any) => (
                                                <div className={styles['info-line']}>
                                                    <span
                                                        className={styles['info-line-left']}>{statuses.find((st) => st.label === status?.status)?.name}</span>
                                                    <span
                                                        className={styles['info-line-right']}>{moment(status.created_at).format('LLL')}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div
                                            className={[styles['status-history-footer'], styles['info-footer']].join(' ')}></div>
                                    </div>

                                    <div className={styles['order-info']}>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Номер заказа:</div>
                                            <span>{order.id}</span>
                                        </div>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Создан:</div>
                                            {moment(order.created_at).format('LLL')}
                                        </div>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Пользователь:</div>
                                            <CellUser field="customer"/>
                                        </div>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Телефон пользователя:</div>
                                            <CellTitle field="customer.phone"/>
                                        </div>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Почта пользователя:</div>
                                            <CellTitle field="customer.email"/>
                                        </div>
                                        <div className={styles['order-row']}>
                                            <div className={styles['order-field']}>Ресторан:</div>
                                            <RecordContext.Provider
                                                value={{
                                                    row: {restaurant: order.restaurant},
                                                    headItem: {
                                                        field: 'restaurant',
                                                        id: genId(),
                                                        label: 'restaurant',
                                                        isSort: false,
                                                        className: '',
                                                        render: () => <></>,
                                                    },
                                                }}
                                            >
                                                <CellSeller/>
                                            </RecordContext.Provider>
                                        </div>
                                        <LabelRow label="Точка приземления"
                                                  content={<OrderLandingPoint isShowCoordinates/>}/>

                                        <LabelRow label="Зона доставки" content={<CellDeliveryZone isShowCoordinates
                                                                                                   field="landing_point.delivery_zone"/>}/>
                                        <LabelRow label="Оплата" content={<CellOrderPaid field=""/>}/>

                                        <LabelRow label="Дрон" content={<SelectDrone disabled={isDrone} value={drone}
                                                                                     placeholder="Назначить дрон"
                                                                                     onChange={createFlightHandler}/>}/>
                                        {isDelivered || !isDrone ? null : (
                                            <>
                                                <LabelRow
                                                    label="Статус дрона"
                                                    content={
                                                        <DroneStatus field="flight.drone"/>
                                                    }
                                                />

                                            </>
                                        )}
                                        {isDrone ?
                                            <LabelRow
                                                label="Разрешение на посадку"
                                                content={<CellFlightLandingPermit

                                                    field="flight"/>}/>
                                            : null}


                                        <LabelRow label="Симуляция" content={<FlightSimulation/>}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.main}>
                        <div className={styles.container}>
                            <div className={styles.content}>

                              <OrderMap/>
                                <div className={styles.more}>
                                    <div className={[styles.order, styles.info].join(' ')}>
                                        <div className={[styles['order-header'], styles['info-header']].join(' ')}>
                                            <h4>Заказ</h4>
                                        </div>
                                        <div className={[styles['order-main'], styles['info-main']].join(' ')}>
                                            {order.items.map((product: any) => (
                                                <OrderProduct product={product} key={product.id}/>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={[styles.total, styles.info].join(' ')}>
                                        <div className={[styles['total-header'], styles['info-header']].join(' ')}>
                                            <h4>Итого</h4>
                                        </div>
                                        <div className={[styles['total-main'], styles['info-main']].join(' ')}>
                                            <div className={styles['info-line']}>
                                                <span>Товары</span>
                                                <span>{order.items_cost}</span>
                                            </div>
                                            <div className={styles['info-line']}>
                                                <span>Доставка</span>
                                                <span>{order.delivery_cost}</span>
                                            </div>
                                        </div>
                                        <div className={[styles['total-footer'], styles['info-footer']].join(' ')}>
                                            <div className={styles['info-line']}>
                                                <span>Оплачено</span>
                                                <span>{(Number(order?.delivery_cost) + Number(order?.items_cost)).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles['']}></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.container}>
                            <div className={styles['footer-main']}></div>
                        </div>
                    </div>
                </div>
            </RecordContext.Provider>
        </OrderProvider>
    )
}

interface IProps {
}

export default OrderShow
