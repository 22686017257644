import { useFlightSimulationMutation } from 'entities/order'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { Button } from 'shared/components'
import {useActions, useRecordContext, useResult} from 'shared/hooks'
import styles from './styles.module.scss'

const FlightSimulation: React.FC<IProps> = () => {
	const record = useRecordContext()
	const order = record?.row
	const { setLoading } = useActions()

	const [flightSimulation, result] = useFlightSimulationMutation()
  useResult({result})

	const startFlightSimulation = async () => {
		try {
			setLoading(true)
			await flightSimulation({ order_id: order.id }).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<div className={styles.flightSimulation}>
			<Button onClick={startFlightSimulation} variant="secondary">
				Запустить тестовый полет
			</Button>
		</div>
	)
}

interface IProps {}
export default FlightSimulation
