import { DroneProdivder, DroneTable } from 'features/drone'
import { ButtonCreate } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const DroneListPage: React.FC<IProps> = () => {
	return (
		<DroneProdivder>
			<LayoutPage
				classNameMain={styles.main}
				label="Дроны"
				main={<DroneTable />}
				right={
					<>
						<ButtonCreate />
					</>
				}
			/>
		</DroneProdivder>
	)
}

interface IProps {}
export default DroneListPage
