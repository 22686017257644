import { FC } from 'react'
import { ProductForm } from 'entities/product'
import { useProductCreateMutation } from 'entities/product'
import { ProductProvider } from 'features/product'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const ProductCreatePage: FC<IProps> = () => {
	const [productCreate, result] = useProductCreateMutation()

	const productCreateHandler = async (data: any) => {
		await productCreate({
			photo_id: data.photo_id,
			description: data.description,
			name: data.name,
			category: data.product_group,
		}).unwrap()
	}

	return (
		<ProductProvider>
			<LayoutPage
				label="Новый продукт"
				classNameMain={styles.main}
				main={<ProductForm buttonLabel="Создать" result={result} product={productCreateHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductCreatePage
