import { FC } from 'react'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { DeliveryZoneForm } from 'features/delivery-zone'
import { useCreateDeliveryZoneMutation } from 'entities/delivery-zone'
import { DroneForm } from 'features/drone'
import { useCreateDroneMutation } from 'entities/drone'

const DroneCreatePage: FC<IProps> = () => {
	const [droneCreate, result] = useCreateDroneMutation()

	const droneCreateHandler = async (data: any) => {
		await droneCreate({
			serial_number: data.serial_number,
      title: data.title,
      registration_number: data.registration_number,
			delivery_zone: data.delivery_zone.id,
		}).unwrap()
	}
	return (
		<SellerProvider>
			<LayoutPage
				label="Новый дрон"
				classNameMain={styles.main}
				main={<DroneForm buttonLabel="Создать" result={result} drone={droneCreateHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default DroneCreatePage
