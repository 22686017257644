/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { EAuthType } from 'entities/auth'
import { getUserName } from 'entities/user/lib'
import { AUTH_TYPE_KEY, TOKEN_KEY } from 'shared/config'
import { useActions } from 'shared/hooks'
import { parseJwt } from 'shared/lib'

const UserProvider: FC<IProps> = (p) => {
	const { children } = p

	const { getUser } = useActions()

	const [cookies] = useCookies([TOKEN_KEY, AUTH_TYPE_KEY])
	const tokenData = parseJwt(cookies[TOKEN_KEY])

	const type = cookies[AUTH_TYPE_KEY]

	const getUserKeyclockHandler = () => {
		const user = tokenData
    const roles = user?.resource_access?.hifly?.roles ?? []
		getUser({
			id: user.sub,
			email: user.email,
			name: user.name,
      roles,
			avatar: user.avatar_256,
		})
	}

	useEffect(() => {
		if (type === EAuthType.KEYCLOCK) getUserKeyclockHandler()
	}, [])

	return <>{children}</>
}

interface IProps {
	children: ReactNode
}
export default UserProvider
