import styles from './styles.module.scss'
import {YMaps, Map, Placemark, Polyline} from '@pbe/react-yandex-maps'
import Dron from './3.svg'
import Arrival from './2.svg'
import Departure from './1.svg'
import {useRecordContext} from 'shared/hooks'
import {useFlightHistoryQuery} from 'entities/order/api'

const Drone: React.FC = () => {
    const orderRow = useRecordContext()
    const order = orderRow?.row
    const flight = order?.flight
  const isDelivered = order?.isDelivered

    const {data: flightHistoryData, isLoading} = useFlightHistoryQuery({
        flight: flight?.id,
        page: 1,
        page_size: 999999999
    }, {pollingInterval: isDelivered? undefined: 2000})

    if (isLoading || !flightHistoryData) return null

    const getflightHistory = flightHistoryData?.results ?? []

    const flightHistory = getflightHistory[getflightHistory.length - 1]
    const drone: {
        coordinates: [number, number]
    } = {coordinates: [flightHistory?.coordinates?.latitude, flightHistory?.coordinates?.longitude]}

    const polylines = getflightHistory.map((item:any) => {
        return [item?.coordinates?.latitude, item?.coordinates?.longitude]
    })

    return (
        <>
            {flightHistory && (
                <Placemark
                    geometry={drone.coordinates}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: Dron,
                      iconOffset:[0,10],
                    }}
                />
            )}
            <Polyline
              geometry={polylines}
                options={{
                strokeColor: "#16151a",
                    strokeWidth: 6,
                  strokeOpacity:0.5,
                }}
            />
        </>
    )
}

const OrderMap: React.FC<IProps> = () => {
    const orderRow = useRecordContext()
    const order = orderRow?.row


    const landingPoint = {coordinates: [order?.landing_point.coordinates.latitude, order?.landing_point.coordinates.longitude]}
    const deliveryZone: {
        coordinates: [number, number]
    } = {coordinates: [order?.delivery_zone?.coordinates?.latitude ?? 50.67429003212223, order?.delivery_zone?.coordinates?.longitude ?? 38.536995346431596]}
    const mapState = {center: deliveryZone.coordinates, zoom: 15}

    return (
        <div className={styles.orderMap}>
            <YMaps>
                <Map defaultState={mapState}>
                    <Placemark
                        options={{
                            // preset: 'islands#blueDeliveryCircleIcon',
                            iconLayout: 'default#image',
                            iconImageHref: Departure,
                        }}
                        geometry={landingPoint.coordinates}
                    />
                    <Placemark
                        options={{
                            // preset: 'islands#blueDeliveryCircleIcon',
                            iconLayout: 'default#image',
                            iconImageHref: Arrival,
                        }}
                        geometry={deliveryZone.coordinates}
                    />
                    {order.flight && <Drone/>}

                </Map>
            </YMaps>
        </div>
    )
}

interface IProps {
}

export default OrderMap
