import { ProductProvider } from 'features/product'
import { FC, memo, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Table, ButtonDelete, ButtonEdit, ButtonShow, CellImg, CellText, CellTitle, ButtonCreate } from 'shared/components'
import { IFilter, IHead } from 'shared/components/table/types'
import { useActions } from 'shared/hooks'
import { useGetProductsQuery } from 'entities/product/api/service'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

// TODO: refactor

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {}

const SellerProductsTable: FC<IProps> = memo((p) => {
	const { groupId } = p
	const { pathname } = useLocation()

	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const params = {
		page: filter.pagination.current_page,
		page_size: filter.pagination.per_page,
		category: groupId,
		ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
	}
	const { data: products, isLoading, isFetching, isError } = useGetProductsQuery(params)

	const head: IHead = {
		id: genId(),
		headClassName: 'h-auto',
		rowClick: 'show',
		resource: 'product',
		bodyClassName: 'items-center flex',
		list: [
			{
				id: genId(),
				isSort: true,
				sort_field: 'id',
				label: 'ID',
				field: 'id',
				className: 'w-24',
				render: () => <CellTitle />,
			},
			{
				id: genId(),
				isSort: false,
				sort_field: 'photo',
				label: 'Фото',
				field: 'photo',
				className: 'w-24',
				render: () => <CellImg />,
			},
			{
				id: genId(),
				isSort: true,
				sort_field: 'name',
				label: 'Имя',
				field: 'name',
				className: 'flex-1',
				render: () => <CellTitle />,
			},
			{
				id: genId(),
				isSort: true,
				sort_field: 'description',
				label: 'Описание',
				field: 'description',
				className: 'flex-1',
				render: () => <CellText />,
			},

			{
				id: genId(),
				isSort: false,
				sort_field: '',
				label: '',
				field: '',
				className: 'w-32',
				render: () => (
					<div className="flex items-center gap-1">
						<ButtonShow />
						<ButtonEdit state={{ prev: pathname, product_group: groupId }} />
						<ButtonDelete />
					</div>
				),
			},
		],
	}

	return (
		<ProductProvider>
			<div className={styles.productTable}>
				<div className={styles.header}>
					<h3 className={styles.title}>Продукты</h3>
					<ButtonCreate state={{ prev: pathname, product_group: groupId }} />
				</div>
				<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} data={products} />
			</div>
		</ProductProvider>
	)
})

interface IProps {
	groupId: Id
}

SellerProductsTable.displayName = 'SellerProductsTable'
export default SellerProductsTable
