import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonBack, ButtonDelete, ButtonEdit, ButtonList, CellSeller } from 'shared/components'
import { RecordContext } from 'shared/contexts'
import styles from './styles.module.scss'
import { useGetProductProfileQuery, useGetProductQuery } from 'entities/product'
import { ProductProvider } from 'features/product'

const ProductProfileShowPage: FC<IProps> = () => {
	const { productProfileId, productId } = useParams()
	const { data: productProfileData, isLoading: productProfileIsLoading } =
		useGetProductProfileQuery(productProfileId)
	const { data: productData, isLoading: productIsLoading } = useGetProductQuery(productId)

	if (productProfileIsLoading)
		return (
			<div className={styles.container}>
				<div>ЗАГРУЗКА ПРОДУКТА...</div>
			</div>
		)

	if (productIsLoading)
		return (
			<div className={styles.container}>
				<div>ЗАГРУЗКА ПРОФИЛЯ ПРОДУКТА...</div>
			</div>
		)

	const product = productData
	const productProfile = productProfileData

	return (
		<ProductProvider>
			<RecordContext.Provider value={{ row: productProfile }}>
				<div className={styles.page}>
					<div className={styles.header}>
						<div className={styles.container}>
							<div className={styles['header-main']}>
								<div className={styles['header-left']}>
									<ButtonBack />

									<div className={styles.info}>
										<img
											alt={productProfile.product_type}
											className={styles.img}
											src={product.photo}
										/>
										<div>
											<span className={styles.group}>{productProfile.product_type}</span>
											<h3 className={styles.name}>{product.name}</h3>
										</div>
									</div>
								</div>
								<div className={styles['header-right']}>
									<ButtonEdit />
									<ButtonList />
									<ButtonDelete />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.main}>
						<div className={styles.container}>
							<div className={styles.content}></div>
						</div>
					</div>
					<div className={styles.footer}>
						<div className={styles.container}>
							<div className={styles['footer-main']}></div>
						</div>
					</div>
				</div>
			</RecordContext.Provider>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductProfileShowPage
