import { useSellerProductGroupDeleteMutation } from 'entities/seller'
import { FC, ReactNode, useEffect } from 'react'
import { ResourceContext } from 'shared/contexts'
import { useActions } from 'shared/hooks'
// TODO: refactor

const SellerProductGroupProvider: FC<IProps> = (p) => {
	const { children, id } = p
	const { setLoading } = useActions()
	const [sellerProductGroupDelete, deleteResult] = useSellerProductGroupDeleteMutation()

	useEffect(() => {
		if (deleteResult.isError) {
			setLoading(false)
		}
		if (deleteResult.isSuccess) {
			setLoading(false)
		}
	}, [deleteResult])

	const deleteProductGroupHandler = async (productGroup: any) => {
		setLoading(true)
		await sellerProductGroupDelete(productGroup.id).unwrap()
	}
	const resource = `seller/${id}/product-group`

	return (
		<ResourceContext.Provider
			value={{
				resource,
				onDelete: deleteProductGroupHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
	id: Id | undefined
}
export default SellerProductGroupProvider
