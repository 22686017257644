import {CustomerRoles, useGetCustomersQuery} from 'entities/customer'
import {FC, memo, useState} from 'react'
import {Table, CellTitle, CellUser, Button, ButtonIcon} from 'shared/components'
import {ESort, IFilter, IHead} from 'shared/components/table/types'
import styles from './styles.module.scss'
import {KeyIcon} from '@heroicons/react/24/outline'
import { genId } from 'shared/lib'

// TODO: refactor

const ButtonCustomerPasswordReset = () => {

    return (
        <ButtonIcon className={styles['button-customer-password-reset']}>
            <KeyIcon/>

        </ButtonIcon>)

}

const head: IHead = {
    id: genId(),
    headClassName: 'h-auto',

    resource: 'order',
    bodyClassName: 'items-center flex ',
    list: [
        {
            id: genId(),
            isSort: true,
            sort_field: 'id',
            label: 'Id',
            field: 'id',
            className: 'w-20',
            render: () => <CellTitle/>,
        },
        {
            id: genId(),
            isSort: false,
            sort_field: 'user',
            label: 'Пользователь',
            field: 'user',
            className: 'flex-1',
            render: () => <CellUser isRow/>,
        },
        {
            id: genId(),
            isSort: false,
            sort_field: 'email',
            label: 'Почта',
            field: 'email',
            className: 'flex-1',
            render: () => <CellTitle/>,
        },
        {
            id: genId(),
            isSort: false,
            sort_field: 'phone',
            label: 'Телефон',
            field: 'phone',
            className: 'flex-1',
            render: () => <CellTitle/>,
        },
        {
            id: genId(),
            isSort: false,
            sort_field: 'roles',
            label: 'Роли',
            field: 'roles',
            className: 'flex-1',
            render: () => <CustomerRoles/>,
        }
    ],
}

const defaultPagation = {
    per_page: 25,
    current_page: 1,
    total_entries: 0,
    total_pages: 0,
}

const defaultFilter = {
    field: 'id',
    sort: ESort.ASC,
}

const OrderTable: FC<IProps> = memo(() => {
    const [filter, setFilter] = useState<IFilter>({
        ...defaultFilter,
        pagination: defaultPagation,
    })

    const params = {
        page: filter.pagination.current_page,
        page_size: filter.pagination.per_page,
        ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
    }
    const {data: customers, isLoading, isFetching, isError} = useGetCustomersQuery(params)

    return (
        <div className={styles.orderTable}>
            <Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading}
                   head={head} data={customers}/>
        </div>
    )
})

interface IProps {
}

OrderTable.displayName = 'OrderTable'
export default OrderTable
