import { FC, ReactNode, useEffect } from 'react'
import { ResourceContext } from 'shared/contexts'
import { useActions } from 'shared/hooks'
import { useProductDeleteMutation } from 'entities/product/api/service'

// TODO: refactor

const ProductProvider: FC<IProps> = (p) => {
	const { children, productId } = p
	const { setLoading } = useActions()

	const [productDelete, deleteResult] = useProductDeleteMutation()

	useEffect(() => {
		if (deleteResult.isError) {
			setLoading(false)
		}
		if (deleteResult.isSuccess) {
			setLoading(false)
		}
	}, [deleteResult])

	const deleteProductHandler = async (product: any) => {
		setLoading(true)
		await productDelete(product.id).unwrap()
	}

	return (
		<ResourceContext.Provider
			value={{
				resource: productId ? `product/${productId}` : 'product',
				onDelete: deleteProductHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
	productId?: Id
}
export default ProductProvider
