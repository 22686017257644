import { FC } from 'react'
import { UserMenu } from 'entities/user'
import { Logo, ThemeSwitch } from 'shared/components'
import Navigations from 'widgets/navigations'
import styles from './styles.module.scss'

const Header: FC<IProps> = () => {
	return (
		<header className={styles.header}>
			<div className={styles.container}>
				<div className={styles.main}>
					<div className={styles.left}>
						<Logo />
						<Navigations />
					</div>
					<div className={styles.right}>
						<ThemeSwitch />
						<UserMenu />
					</div>
				</div>
			</div>
		</header>
	)
}

interface IProps {}
export default Header
