import {OrderProvider, OrderTable} from 'features/order'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const OrderListPage: React.FC<IProps> = () => {
    return (
        <OrderProvider>
            <LayoutPage
                classNameMain={styles.main}
                label="Заказы"
                main={<OrderTable/>}
                right={<>

                </>}
            />
        </OrderProvider>
    )
}

interface IProps {
}

export default OrderListPage
