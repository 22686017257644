import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import ButtonIcon from '../button-icon'
// TODO: memo

const ButtonBack: FC<IProps> = (p) => {
	const { to } = p
	const navigate = useNavigate()

	const backHandler = () => {
		if (to) return navigate(to)
		navigate(-1)
	}

	return (
		<ButtonIcon onClick={backHandler}>
			<ArrowUturnLeftIcon />
		</ButtonIcon>
	)
}

interface IProps {
	to?: string
}
export default ButtonBack
