import { useGetSellerProductGroupsQuery } from 'entities/seller/api'
import { SellerProductGroupProvider } from 'features/seller'
import SellerProductsTable from 'features/seller/components/seller-product-table'
import { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Empty } from 'shared/components'
import SellerProductGroup from '../seller-product-group'
import styles from './styles.module.scss'

const SellerShowProduct: FC<IProps> = () => {
	const { sellerId } = useParams()

	const { data: productGroupsData, isLoading } = useGetSellerProductGroupsQuery({
		restaurant: sellerId,
	})
	const productGroups = productGroupsData ?? []

	if (isLoading) return <div>is_loading</div>

	return (
		<SellerProductGroupProvider id={sellerId}>
			<div className={styles.sellerShowProduct}>
				<div className="h-full">
					<SellerProductGroup groups={productGroups} />
				</div>
				{productGroups.length !== 0 ? (
					<Routes>
						{productGroups.map((item: any) => (
							<Route key={item.id} path={`product-group/${item.id}`} element={<SellerProductsTable groupId={item.id} />} />
						))}
						<Route path="*" element={<Navigate replace to={`product-group/${productGroups[0].id}`} />} />
					</Routes>
				) : (
					<div className={styles.empty}>
						<Empty label="Категория не выбрано" />
					</div>
				)}
			</div>
		</SellerProductGroupProvider>
	)
}

interface IProps {}
export default SellerShowProduct
