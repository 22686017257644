import { FC } from 'react'
import { useRecordContext, useResourceContext } from 'shared/hooks'
import { TrashIcon } from '@heroicons/react/24/outline'
import ButtonIcon from '../button-icon'
import { Link, useLocation } from 'react-router-dom'
// TODO: memo

const ButtonDelete: FC<IProps> = () => {
	const { pathname } = useLocation()
	const resourceContext = useResourceContext()
	const recordContext = useRecordContext()

	const deleteHandler = () => resourceContext?.onDelete(recordContext?.row)

	return (
		<Link to={pathname}>
			<ButtonIcon onClick={deleteHandler}>
				<TrashIcon />
			</ButtonIcon>
		</Link>
	)
}

interface IProps {}
export default ButtonDelete
