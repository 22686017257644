import { ComponentProps, ElementType, forwardRef, ReactNode } from 'react'
import InputField from '../input-field'
import styles from './styles.module.scss'

// TODO: вынести логику и отрефакторить

type InputOwnProps<E extends ElementType = ElementType> = {
	as?: 'input' | 'textarea'
	error?: any
	label?: string
	right?: ReactNode
}

type InputProps<E extends ElementType> = InputOwnProps<E> &
	Omit<ComponentProps<E>, keyof InputOwnProps>

const defaultElement = 'input'

function Input<E extends ElementType = typeof defaultElement>(
	{ children, variant, error, onChange, as, label, right, ...otherProps }: InputProps<E>,
	ref: any
): JSX.Element {
	const TagName = as || defaultElement

	return (
		<InputField error={error?.message} label={label}>
			<TagName
				ref={ref}
				className={[styles.input, error && styles.error].join(' ')}
				{...otherProps}
			></TagName>
		</InputField>
	)
}

export default forwardRef(Input)
