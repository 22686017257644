import { AuthKeycloakPage, AuthLoginPage } from 'pages/auth'
import { Navigate, useRoutes } from 'react-router-dom'
import { paths } from './config'

const AuthRoutes = () => {
	const auth = useRoutes([
		{
			path: paths.AUTH_LOGIN,
			element: <AuthLoginPage />,
		},
		{
			path: paths.AUTH_KEYCLOCK,
			element: <AuthKeycloakPage />,
		},
		{
			path: '*',
			element: <Navigate to={paths.AUTH_KEYCLOCK} replace />,
		},
	])

	return auth
}

export default AuthRoutes
