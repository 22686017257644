import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

const InputField: FC<IProps> = (p) => {
	const { label, error, children } = p
	return (
		<div className={styles.block}>
			<span className={styles.label}>{label}</span>
			<div className={styles.children}>{children}</div>
			<div className={[styles['error-block'], error && styles['error-active']].join(' ')}>
				{error}
			</div>
		</div>
	)
}

interface IProps {
	error?: string
	label?: string
	children: ReactNode
}
export default InputField
