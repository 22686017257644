export interface RootState {
	statuses: any[]
}

export const initialState: RootState = {
	statuses: [
		{
			name: 'Заказ создан',
			label: 'new',
			color: 'border-gray-200 dark:bg-gray-700 dark:text-gray-100  bg-white text-gray-900',
		},
		{ name: 'Заказ принят', label: 'accepted', color: 'border-pink-400 bg-pink-500 text-white' },
		{
			name: 'Заказ готовотся',
			label: 'in_progress',
			color: 'border-orange-400 bg-orange-500 text-white',
		},
		{
			name: 'Заказ готов',
			label: 'ready_for_shipping',
			color: 'border-blue-400 bg-blue-500 text-white',
		},
		{ name: 'Заказ в пути', label: 'moving', color: 'border-yellow-400 bg-yellow-500 text-white' },
		{
			name: 'Заказ доставлен',
			label: 'delivered',
			color: 'border-green-400 bg-green-500 text-white',
			is_delivered: true,
		},
		{ name: 'Отменен', label: 'cancelled', color: 'border-red-400 bg-red-500 text-white' },
	],
}
