import {CellCustomerRole} from 'features/customer'
import {Link, useLocation} from 'react-router-dom'
import {ButtonIcon, NeedCall} from 'shared/components'
import {useRecordContext} from 'shared/hooks'
import {roles} from 'shared/config'
import {getPropertyValue} from 'shared/lib'
import {MinusIcon, PlusIcon} from '@heroicons/react/24/outline'
import styles from './styles.module.scss'



const CustomerRoles: React.FC<IProps> = () => {
    const tableRow = useRecordContext()
    const {pathname} = useLocation()

    if (!tableRow?.headItem) return null
    const userRoles = getPropertyValue(tableRow?.row, tableRow?.headItem.field)

    return (
        <Link to={pathname}>
            <div className={styles['roles']}>
              {userRoles.map((userRole: any) => {
                  const roleKey = Object.keys(userRole)[0]

                const roleName = roles?.find((role) => role?.value === roleKey)
                    return (
                        <div className={styles['role-row']}>
                            <div className={styles.role}>
                                {roleName?.name}

                                {/* <ButtonIcon className={styles['button-delete']}>
                              <MinusIcon />
                            </ButtonIcon> */}
                            </div>
                        </div>
                    )
                })}
                {/* <ButtonIcon className={styles['button-create']}>
          <PlusIcon />
        </ButtonIcon>
              */}
            </div>
        </Link>
    )
}

interface IProps {
}

export default CustomerRoles
