import { ComponentProps, ElementType, memo, ReactNode } from 'react'
import styles from './styles.module.scss'

// TODO: вынести логику

type ButtonOwnProps<E extends ElementType = ElementType> = {
	children: ReactNode
	as?: E
	variant?: 'primary' | 'secondary' | 'hover'
	className?: string
}

type ButtonProps<E extends ElementType> = ButtonOwnProps<E> & Omit<ComponentProps<E>, keyof ButtonOwnProps>

const defaultElement = 'button'
const defaultVariant = 'primary'

function Button<E extends ElementType = typeof defaultElement>(p: ButtonProps<E>): JSX.Element {
	const { children, variant, as, ...otherProps } = p

	const TagName = as || defaultElement
	const variantName = variant || defaultVariant

	return (
		<TagName {...otherProps} className={[styles.button, p.className, styles[variantName]].join(' ')}>
			{children}
		</TagName>
	)
}

export default memo(Button)
