import {useRecordContext} from 'shared/hooks'
import {getPropertyValue} from 'shared/lib'
import styles from './styles.module.scss'

const OrderLandingPoint: React.FC<IProps> = (p) => {
    const {isShowCoordinates} = p
    const tableRow = useRecordContext()
    if (!tableRow?.headItem) return null
    const landingPoint = getPropertyValue(tableRow?.row, 'landing_point')

    return <div className={styles.orderLandingPoint}>
        {landingPoint.address}
        {isShowCoordinates &&

            <span className={styles.coordinates}>

        {landingPoint.coordinates.latitude}{", "}
                {landingPoint.coordinates.longitude}
      </span>}
    </div>
}

interface IProps {
    isShowCoordinates?: boolean
}

export default OrderLandingPoint
