import { CellOrderStatus, useGetOrdersQuery } from 'entities/order'
import { FC, memo, useState } from 'react'
import { Table, CellTitle, CellSeller, CellUser, CellDate, CellDrone } from 'shared/components'
import { ESort, IFilter, IHead } from 'shared/components/table/types'
import CellOrderPaid from '../order-paid'
import styles from './styles.module.scss'
import { DroneStatus } from '../../../../entities/drone'
import { genId } from 'shared/lib'

// TODO: refactor

const head: IHead = {
	id: genId(),
	headClassName: 'h-auto',
	rowClick: 'show',
	resource: 'order',
	bodyClassName: 'items-center flex ',
	list: [
		{
			id: genId(),
			isSort: true,
			sort_field: 'id',
			label: 'Id',
			field: 'id',
			className: 'w-20',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'customer',
			label: 'Пользователь',
			field: 'customer',
			className: 'flex-1',
			render: () => <CellUser />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'restaurant',
			label: 'Ресторан',
			field: 'restaurant',
			className: 'flex-1',
			render: () => <CellSeller />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'drone',
			label: 'Дрон',
			field: 'flight.drone',
			className: 'flex-1',
			render: () => <CellDrone />,
		},
		{
			id: genId(),
			isSort: true,
			sort_field: 'drone_status',
			label: 'Статус дрона',
			field: 'flight.drone',
			className: 'flex-1',
			render: () => <DroneStatus />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'is_paid',
			label: 'Оплата',
			field: '',
			className: 'w-36',
			render: () => <CellOrderPaid />,
		},
		{
			id: genId(),
			isSort: true,
			sort_field: 'delivery_cost',
			label: 'Итого',
			field: 'delivery_cost',
			className: 'w-28',
			render: () => <CellTitle render={(order: any) => (Number(order?.delivery_cost) + Number(order?.items_cost)).toFixed(2)} />,
		},
		{
			id: genId(),
			isSort: true,
			sort_field: 'status',
			label: 'Статус',
			field: 'status',
			className: 'w-48',
			render: () => <CellOrderStatus />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'created_at',
			label: 'Дата создания',
			field: 'created_at',
			className: 'w-52',
			render: () => <CellDate />,
		},
	],
}

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {
	field: 'id',
	sort: ESort.ASC,
}

const OrderTable: FC<IProps> = memo(() => {
	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const params = {
		page: filter.pagination.current_page,
		page_size: filter.pagination.per_page,
		ordering: `${filter.sort === 'desc' ? '' : '-'}${filter.field}`,
	}
	const { data: orders, isLoading, isFetching, isError } = useGetOrdersQuery(params)

	return (
		<div className={styles.orderTable}>
			<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} data={orders} />
		</div>
	)
})

interface IProps {}

OrderTable.displayName = 'OrderTable'
export default OrderTable
