import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL, TOKEN_KEY } from 'shared/config'
import { prepareHeaders } from '../config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const fileAPI = createApi({
	reducerPath: 'file-api',
	tagTypes: [],
	baseQuery,
	endpoints: (build) => ({}),
})

export const upload = (file: any, field: any) => {
	const token = document.cookie
		.split('; ')
		.find((row) => row.startsWith(`${TOKEN_KEY}=`))
		?.split('=')[1]
	const headers = new Headers()

	headers.append('Authorization', `Bearer ${token}`)

	const formdata = new FormData()
	formdata.append(field, file)

	const requestOptions = {
		method: 'POST',
		headers,
		body: formdata,
	}

	return fetch(`${API_URL}/media/photo/?as=admin`, requestOptions).then((res) => res.json())
}

export const {} = fileAPI
