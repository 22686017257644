import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import styles from './styles.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useActions, useResourceContext } from 'shared/hooks'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import { SelectMeasureLength, SelectMeasureWeight } from 'shared/components'

// TODO: refactor

interface FormData {
	name: string
	measure_weight: any
	measure_length: any
	quantity_left: number

	price: number
	weight: number
	size: number
}

const schema = yup
	.object({
		name: yup.string().required('Введите название'),
		price: yup.string().required('Введите цену'),
		quantity_left: yup.string().required('Введите колличество'),
		weight: yup.string().required('Введите вес'),
		size: yup.string().required('Введите размер'),
	})
	.required()

const ProductProfileForm: FC<IProps> = (p) => {
	const { result, product, defaultValues, buttonLabel } = p

	const resourceContext = useResourceContext()

	const navigate = useNavigate()
	const { setLoading } = useActions()
	const { state } = useLocation()

	const {
		register,
		handleSubmit,
		formState: { errors },
		setFocus,
	} = useForm<FormData>({
		defaultValues: {
			...defaultValues,
		},
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		setFocus('name')
	}, [])

	const successHandler = () => {
		const to = `/${resourceContext?.resource}/show`

		navigate(to)
		setLoading(false)
	}
	const errorHandler = () => {
		setLoading(false)
	}

	useEffect(() => {
		if (result.isError) return errorHandler()
		if (result.isSuccess) return successHandler()
	}, [result])

	const onSubmit = async (data: FormData) => {
		try {
			setLoading(true)
			const productBody = {
				...data,
				...state,
				price: +data.price,
				quantity_left: +data.quantity_left,
				weight: +data.weight,
				size: +data.size,
				measure_weight: 1,
				measure_length: 1,
			}

			await product(productBody)
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
			<div className={[styles.col, styles.flexRight].join(' ')}>
				<div className={styles.row}>
					<Input label="Название" error={errors?.name} {...register('name')} placeholder="Введите название" />
				</div>
				<div className={styles.row}>
					<div className={styles.row}>
						<Input label="Колличесто" type="number" error={errors?.quantity_left} {...register('quantity_left')} placeholder="Введите колличество" />
					</div>
					<div className={styles.row}>
						<Input label="Цена" type="number" error={errors?.price} {...register('price')} placeholder="Введите цену" />
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.row}>
						<Input label="Ширина" type="number" error={errors?.weight} {...register('weight')} placeholder="Введите ширину" />
						<SelectMeasureLength error={errors?.measure_length} label="Еи" />
					</div>
					<div className={styles.row}>
						<Input label="Размер" type="number" error={errors?.size} {...register('size')} placeholder="Введите размер" />
						<SelectMeasureWeight error={errors?.measure_weight} label="Еи" />
					</div>
				</div>

				<div className={styles.row}>
					<Button type="submit">{buttonLabel}</Button>
				</div>
			</div>
		</form>
	)
}

interface IProps {
	result: any
	product: any
	buttonLabel: any
	defaultValues?: any
}
export default ProductProfileForm
