import { usePaymentOrderMutation } from 'entities/order'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { CellPathname } from 'shared/components'
import { useActions, useRecordContext,useResult } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import styles from './styles.module.scss'

const statuses = [
	{
		id: 1,
		value: true,
		name: 'Оплачено',
		color: 'bg-green-600/5 dark:bg-green-600/10 text-green-600 border border-green-600/20 dark:border-green-600/20',
	},
	{
		id: 1,
		value: false,
		name: 'Неоплачено',
		color: 'bg-red-600/5 dark:bg-red-600/10 text-red-600 border border-red-600/20 dark:border-red-600/20',
	},
]

const CellOrderPaid: React.FC<IProps> = (p) => {
	const { field } = p
	const tableRow = useRecordContext()
	const { setLoading } = useActions()
	const [paymentOrder, result] = usePaymentOrderMutation()
  useResult({result})



	if (!tableRow?.headItem) return null

	const order = getPropertyValue(tableRow?.row, field ?? tableRow?.headItem.field)

  if (order === null) return null

  const orderId = getPropertyValue(order, 'id')
  const isPaid = getPropertyValue(order, 'is_paid')

	const color = statuses?.find((paidStatus) => paidStatus?.value === isPaid)?.color

	const onChangePaymentOderStatus = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		try {
			setLoading(true)
			const value = event.target.value
			await paymentOrder({ id: orderId, body: { is_paid: value } }).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}



	return (
		<CellPathname>
			<select onChange={onChangePaymentOderStatus} value={isPaid} className={[styles.select, color].join(' ')}>
				{statuses.map((status: any) => (
					<option key={status.id} value={status.value}>
						{status.name}
					</option>
				))}
			</select>
		</CellPathname>
	)
}

interface IProps {
	field?: string
}
export default CellOrderPaid
