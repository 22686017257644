import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const deliveryZoneAPI = createApi({
	reducerPath: 'delivery-zone-api',
	tagTypes: ['DeliveryZones'],
	baseQuery,
	endpoints: (build) => ({
		getDeliveryZones: build.query<any, any>({
			query: (params) => ({
				url: `/delivery-zone/`,
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['DeliveryZones'],
		}),
		createDeliveryZone: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: `/delivery-zone/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['DeliveryZones'],
		}),
		editDeliveryZone: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: `/delivery-zone/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['DeliveryZones'],
		}),
		deleteDeliveryZone: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/delivery-zone/${id}`,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['DeliveryZones'],
		}),
	}),
})

export const {
	useGetDeliveryZonesQuery,
	useCreateDeliveryZoneMutation,
	useDeleteDeliveryZoneMutation,
} = deliveryZoneAPI
