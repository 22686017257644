import { getUserName } from 'entities/user/lib'
import { FC, useState } from 'react'
import { useDebounce } from 'shared/hooks'
import { useGetUsersQuery } from 'shared/services/user'
import Select from '../select'
import UserView from '../user-view'

const SelectUser: FC<IProps> = (p) => {
    const {filter, ...otherProps } = p

	const [query, setQuery] = useState<string>('')
	const search = useDebounce(query, 500)
  const { data: usersData } = useGetUsersQuery({ search,...filter })
	const users = usersData?.results ?? []

	return (
		<Select
			query={query}
			setQuery={setQuery}
			displayValue={(value) => (value ? getUserName(value) : '')}
			filteredField={(value) => getUserName(value)}
			renderOption={(value) => (
				<>
					<UserView user={value} />
				</>
			)}
			list={users}
			{...otherProps}
		/>
	)
}

interface IProps {
	label?: string
	value: any
	filter?: any
	onChange: (value: any) => void
	error?: any
	placeholder: string
}
export default SelectUser
