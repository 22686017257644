import { ProductProfileProvider } from 'features/product'
import { FC, memo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Table, ButtonDelete, ButtonEdit, ButtonShow, CellTitle, ButtonCreate, CellText, CellImg } from 'shared/components'
import { IFilter, IHead } from 'shared/components/table/types'
import { useGetProductProfilesQuery, useGetProductQuery } from 'entities/product/api/service'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

// TODO: refactor

const defaultPagation = {
	per_page: 25,
	current_page: 1,
	total_entries: 0,
	total_pages: 0,
}

const defaultFilter = {}
const head: IHead = {
	id: genId(),
	headClassName: 'h-auto',
	resource: 'product',
	bodyClassName: 'items-center flex h-16',
	list: [
		{
			id: genId(),
			isSort: false,
			sort_field: 'id',
			label: 'ID',
			field: 'id',
			className: 'w-24',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'name',
			label: 'Название',
			field: 'name',
			className: 'flex-1',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'size',
			label: 'Размер',
			field: 'size',
			className: 'w-32',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'weight',
			label: 'Масса/Объем',
			field: 'weight',
			className: 'w-32',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'quantity_left',
			label: 'Количество',
			field: 'quantity_left',
			className: 'w-32',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: 'price',
			label: 'Цена',
			field: 'price',
			className: 'w-32',
			render: () => <CellTitle />,
		},
		{
			id: genId(),
			isSort: false,
			sort_field: '',
			label: '',
			field: '',
			className: 'w-24',
			render: () => (
				<div className="flex items-center gap-1">
					<ButtonEdit />
					<ButtonDelete />
				</div>
			),
		},
	],
}

const ProductProfileTable: FC<IProps> = memo((p) => {
	const {} = p

	const { productId } = useParams()
	const { pathname } = useLocation()

	const [filter, setFilter] = useState<IFilter>({
		...defaultFilter,
		pagination: defaultPagation,
	})

	const { data: product, isLoading, isError, isFetching } = useGetProductQuery(productId)

	const productProfiles = {
		results: product?.profiles ?? [],
		count: product?.profiles?.length ?? 0,
	}

	return (
		<ProductProfileProvider productId={productId}>
			<div className={styles.productProfileTable}>
				<div className={styles.header}>
					<h3 className={styles.title}>Профили продуктов</h3>
					<ButtonCreate state={{ product: productId, prev: pathname }} />
				</div>
				<Table isError={isError} isFetching={isFetching} setFilter={setFilter} filter={filter} isLoading={isLoading} head={head} hidePagination data={productProfiles} />
			</div>
		</ProductProfileProvider>
	)
})

interface IProps {}

ProductProfileTable.displayName = 'ProductProfileTable'
export default ProductProfileTable
