import {getDroneStatuses} from 'entities/drone'
import {useSelector} from 'react-redux'
import {useRecordContext} from 'shared/hooks'
import {getPropertyValue} from 'shared/lib'
import {CellEmpty} from 'shared/components'

import styles from './styles.module.scss'

const CellDrone: React.FC<IProps> = () => {
    const statuses: any[] = useSelector(getDroneStatuses)

    const tableRow = useRecordContext()
    if (!tableRow?.headItem) return null
    const drone = getPropertyValue(tableRow?.row, tableRow?.headItem.field)

    const status = statuses?.find((status) => status?.label === drone?.drone_status)

    if (!drone) return <CellEmpty/>

    return (
        <div className={styles.drone}>
          <span className={styles.name}>{drone.title} ({drone.registration_number})</span>
        </div>
    )
}

interface IProps {
}

export default CellDrone
