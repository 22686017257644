import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const sellerAPI = createApi({
	reducerPath: 'seller-api',
	tagTypes: ['Seller', 'Sellers', 'SellerProductGroup', 'SellerProductGroups'],
	baseQuery,
	endpoints: (build) => ({
		getSellers: build.query<any, any>({
			query: (params) => ({
				url: '/restaurants/',
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Sellers'],
		}),
		getSeller: build.query<any, any>({
			query: (id) => ({
				url: `/restaurants/${id}/`,
				params: {
					as: 'admin',
				},
			}),

			providesTags: () => ['Seller'],
		}),
		sellerCreate: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: '/restaurants/',
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Sellers'],
		}),
		sellerEdit: build.mutation<any, any>({
			query: ({ id, body }) => ({
				method: 'PATCH',
				url: `/restaurants/${id}/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Sellers'],
		}),
		sellerDelete: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/restaurants/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Sellers'],
		}),

		getSellerProductGroups: build.query<any, any>({
			query: (params) => ({
				url: `/categories/`,
				params: {
					as: 'admin',
					...params,
				},
			}),
			providesTags: () => ['SellerProductGroups'],
		}),

		getSellerProductGroup: build.query<any, any>({
			query: (id) => ({
				url: `/categories/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['SellerProductGroups'],
		}),

		sellerProductGroupCreate: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: '/categories/',
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['SellerProductGroups'],
		}),
		sellerProductGroupEdit: build.mutation<any, any>({
			query: ({ body, id }) => ({
				method: 'PATCH',
				url: `/categories/${id}/`,
				body: JSON.stringify(body),
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['SellerProductGroups'],
		}),
		sellerProductGroupDelete: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/categories/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['SellerProductGroups'],
		}),
	}),
})

export const {
	useGetSellerQuery,
	useGetSellersQuery,
	useSellerCreateMutation,
	useSellerEditMutation,
	useSellerDeleteMutation,
	useSellerProductGroupCreateMutation,
	useGetSellerProductGroupsQuery,
	useGetSellerProductGroupQuery,
	useSellerProductGroupEditMutation,
	useSellerProductGroupDeleteMutation,
} = sellerAPI
