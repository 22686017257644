import { FC } from 'react'
import { useParams } from 'react-router-dom'
import {
	SellerProductGroupForm,
	useGetSellerProductGroupQuery,
	useSellerProductGroupEditMutation,
} from 'entities/seller'
import { SellerProductGroupProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const SellerProductGroupEditPage: FC<IProps> = () => {
	const { sellerId, groupId } = useParams()

	const { data: productGroupData, isLoading } = useGetSellerProductGroupQuery(groupId)
	const [productGroupEdit, result] = useSellerProductGroupEditMutation()

	const productGroupEditHandler = async (data: any) => {
		await productGroupEdit({
			id: data.groupId,
			body: { name: data.name },
		}).unwrap()
	}

	const productGroup = productGroupData
	const defaultValues = {
		...productGroup,
	}

	return (
		<SellerProductGroupProvider id={sellerId}>
			<LayoutPage
				label="Редактирование категории"
				classNameMain={styles.main}
				main={
					isLoading ? (
						<div>get seller product group isLoading...</div>
					) : (
						<SellerProductGroupForm
							defaultValues={defaultValues}
							result={result}
							productGroup={productGroupEditHandler}
						/>
					)
				}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProductGroupProvider>
	)
}

interface IProps {}
export default SellerProductGroupEditPage
