import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ListBulletIcon } from '@heroicons/react/24/outline'
import ButtonIcon from '../button-icon'
import { useResourceContext } from 'shared/hooks'

// TODO: memo

const ButtonList: FC<IProps> = (p) => {
	const resourceContext = useResourceContext()

	const to = `/${resourceContext?.resource}`

	return (
		<Link to={to}>
			<ButtonIcon>
				<ListBulletIcon />
			</ButtonIcon>
		</Link>
	)
}

interface IProps {}
export default ButtonList
