import { FC, Fragment, memo, useCallback, useEffect } from 'react'
import styles from './styles.module.scss'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { ESort, IFilter, IHead, IHeadListItem, IMeta, IPagination } from './types'
import TableContext from 'shared/contexts/table-context'
import RecordContext from 'shared/contexts/record-context'
import Pagination from '../pagination'
import { useResourceContext } from 'shared/hooks'
import {Link, useLocation} from 'react-router-dom'
import { createMeta } from 'shared/lib'

// TODO: refactor

const Table: FC<IProps> = memo((p) => {
	const { data, head, isLoading, setFilter, filter, isFetching, isError, hidePagination } = p
	const resourceContext = useResourceContext()
  const {hash} = useLocation()

	const list = data?.results ?? []

	useEffect(() => {
		if (!isLoading) {
			const count = data?.count ?? 0
			const meta = createMeta(count, filter.pagination)
			setFilter({ ...filter, pagination: { ...filter.pagination, ...meta } })
		}
	}, [isFetching, isLoading])

	const sortsDisabled = isFetching

	const setSortHandler = useCallback(
		(head: IHeadListItem) => {
			if (sortsDisabled ? true : !head.isSort) return null
			setFilter({
				...filter,
				field: head.sort_field,
				sort: head.sort_field !== filter?.field ? ESort.ASC : filter?.sort === ESort.ASC ? ESort.DESC : ESort.ASC,
			})
		},
		[filter]
	)

	const setPagination = (pagination: IPagination) => {
		setFilter({ ...filter, pagination: { ...pagination } })
	}

	if (isLoading) return <div></div>
	if (isError) return <div></div>


	return (
		<TableContext.Provider value={{ head, list }}>
			<div className={styles.block}>
				<div className={styles.table}>
					<div className={styles.thead}>
						<div className={styles.tr}>
							{head.list.map((headItem, index: number) => (
								<Fragment key={headItem.id}>
									<div
										onClick={() => setSortHandler(headItem)}
										className={[styles.th, head.headClassName, headItem.className, headItem.isSort ? 'cursor-pointer hover:bg-gray-100/50 dark:hover:bg-gray-800/50' : 'cursor-default hover:bg-transparent', sortsDisabled ? 'cursor-not-allowed' : ''].join(' ')}
									>
										{headItem.label}
										{headItem.isSort && filter?.field === headItem.sort_field && (
											<div className={styles['head-icons']}>
												<ChevronUpIcon className={[styles['head-icon'], filter.sort === ESort.ASC && styles['head-icon-active']].join(' ')} />
												<ChevronDownIcon className={[styles['head-icon'], filter.sort === ESort.DESC && styles['head-icon-active']].join(' ')} />
											</div>
										)}
									</div>
									{index !== head.list.length - 1 && <div className={styles.divider} />}
								</Fragment>
							))}
						</div>
					</div>
					<div className={styles.tbody}>
						{list.map((item: any) => (
							<Link key={item.id} to={head.rowClick ? `/${resourceContext?.resource}/${item.id}/${head.rowClick}` : `#${item.id}`}>
                <div className={[styles.tr, head.rowClick ? 'cursor-pointer' : 'cursor-default',hash === `#${item.id}` ? styles['tr-active']:""].join(' ')}>
									{head.list.map((headItem, index: number) => (
										<Fragment key={headItem.id}>
											<RecordContext.Provider value={{ row: item, headItem, head }}>
												<div className={[styles.td, head.bodyClassName, headItem.className].join(' ')}>{headItem.render()}</div>
											</RecordContext.Provider>
											{index !== head.list.length - 1 && <div className={styles.divider} />}
										</Fragment>
									))}
								</div>
							</Link>
						))}
					</div>
				</div>
				{!hidePagination && (
					<div className="sticky bottom-0">
						<Pagination isFetching={isFetching} setPagination={setPagination} pagination={filter.pagination} />
					</div>
				)}
			</div>
		</TableContext.Provider>
	)
})

interface IProps {
	data: any
	head: IHead
	isLoading: boolean
	isFetching: boolean
	filter: IFilter
	isError: boolean
	hidePagination?: boolean
	setFilter: (filter: IFilter) => void
}

Table.displayName = 'Table'
export default Table
