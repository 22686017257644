import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import {API_URL} from 'shared/config'
import {prepareHeaders} from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
})

export const feedbackAPI = createApi({
    reducerPath: 'feedback-api',
    tagTypes: ['FeedBack', 'FeedBacks'],
    baseQuery,
    endpoints: (build) => ({
        getFeedBacks: build.query<any, any>({
            query: (params) => ({
                url: `/feedbacks/`,
                params: {
                    ...params,
                    as: 'admin',
                },
            }),
            providesTags: () => ['FeedBacks'],
        }),
      getFeedBack: build.query<any, any>({
        query: (id) => ({
          url: `/feedbacks/${id}`,
          params: {

            as: 'admin',
          },
        }),
        providesTags: () => ['FeedBack'],
      }),
        editFeedBack: build.mutation<any, any>({
            query: ({id, body}) => ({
                method: "PATCH",
                url: `/feedbacks/${id}`,
                body,
                params: {
                    as: 'admin',
                },
            }),
            invalidatesTags: () => ['FeedBacks', "FeedBack"],
        }),

    }),
})

export const {useGetFeedBacksQuery,useEditFeedBackMutation,useGetFeedBackQuery} = feedbackAPI
