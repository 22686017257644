// import compose from 'compose-function'
// import withAuth from './with-auth'
// import withKeyclock from './with-keyclock'
// import withRouter from './with-router'
// import withStore from './with-store'
// import { withCookies } from 'react-cookie'

// export const withProviders = compose(withCookies, withRouter, withKeyclock, withStore, withAuth)

import { FC, ReactNode } from 'react'
import UserProvider from './user-provider'
import KeycloakProvider from './keyclock-provider'
import { CookiesProvider } from 'react-cookie'
import StoreProvider from './store-provider'
import RouterProvider from './router-provider'

import AuthProvider from './auth-provider'

const Providers: FC<IProps> = (p) => {
	const { children } = p

	return (
		<RouterProvider>
			<CookiesProvider>
				<StoreProvider>
					<KeycloakProvider>
						<AuthProvider>
							<UserProvider>{children}</UserProvider>
						</AuthProvider>
					</KeycloakProvider>
				</StoreProvider>
			</CookiesProvider>
		</RouterProvider>
	)
}

interface IProps {
	children: ReactNode
}
export default Providers
