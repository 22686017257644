const getPropertyValue = (obj1: any, dataToRetrieve: any) => {

  if (!dataToRetrieve) return obj1

    return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function (o: any, k: any) {
            return o && o[k] // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
}
export default getPropertyValue
