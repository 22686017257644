import { useDeleteDeliveryZoneMutation } from 'entities/delivery-zone'
import { FC, ReactNode, useEffect } from 'react'
import { ResourceContext } from 'shared/contexts'
import { useActions } from 'shared/hooks'

// TODO: refactor

const DeliveryZoneProvider: FC<IProps> = (p) => {
	const { children } = p

	const { setLoading } = useActions()

	const [deleteDeliveryZone, result] = useDeleteDeliveryZoneMutation()

	useEffect(() => {
		if (result.isError) {
			setLoading(false)
		}
		if (result.isSuccess) {
			setLoading(false)
			// navigate('/seller')
		}
	}, [result])

	const deleteHandler = async (deliveryZone: any) => {
		try {
			setLoading(true)
			await deleteDeliveryZone(deliveryZone.id).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<ResourceContext.Provider
			value={{
				resource: 'delivery-zone',
				onDelete: deleteHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
}
export default DeliveryZoneProvider
