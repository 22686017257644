import { IMeta, IPagination } from 'shared/components/table/types'

const createMeta = (counts: number, pagination: IPagination): IMeta => {
	return {
		per_page: pagination.per_page,
		current_page: pagination.current_page,
		total_entries: counts,
		total_pages: Math.ceil(counts / pagination.per_page),
	}
}
export default createMeta
