import { FC, memo } from 'react'
import styles from './styles.module.scss'
import { Link, useMatch } from 'react-router-dom'
import { paths } from 'app/routes'
import { BuildingStorefrontIcon, UsersIcon, ChatBubbleLeftEllipsisIcon, MapPinIcon, RocketLaunchIcon, RssIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'

// TODO: сделать масив
// TODO: поменять ui активного меню
// TODO: добавить иконку
// TODO: сделать отдельный компонент для menu-item

const Navigations: FC = memo(() => {
	return (
		<nav className={styles.menu}>
			<ul className={styles.list}>
				<NavigationItem icon={<BuildingStorefrontIcon />} to={paths.SELLERS_LIST} label="Рестораны" />
				<NavigationItem icon={<RssIcon />} to={paths.ORDER_LIST} label="Заказы" />
				<NavigationItem icon={<RocketLaunchIcon />} to={paths.DRONE_LIST} label="Дроны" />
				<NavigationItem icon={<MapPinIcon />} to={paths.DELIVERY_ZONE_LIST} label="Зоны доставки" />
				<NavigationItem icon={<PaperAirplaneIcon />} to={paths.FLIGHT_LIST} label="Полеты" />
				<NavigationItem icon={<UsersIcon />} to={paths.CUSTOMER_LIST} label="Пользователи" />
				<NavigationItem badge="бета" icon={<ChatBubbleLeftEllipsisIcon />} to={paths.FEEDBACK_LIST} label="Обратная связь" />
			</ul>
		</nav>
	)
})

const NavigationItem: FC<IINavigationProps> = memo((p) => {
	const { to, label, icon, badge } = p

	const math = useMatch({
		path: to,
		end: to.length === 1,
	})

	return (
		<Link to={to}>
			<li className={[styles.item, math && styles.active].join(' ')}>
				{icon}
				{label}
				{badge && <div className={styles.badge}>{badge}</div>}
				<div className={styles.line} />
			</li>
		</Link>
	)
})

interface IINavigationProps {
	to: string
	label: string
	badge?: string
	icon: React.ReactNode
}

Navigations.displayName = 'Navigations'
export default Navigations
