import { FC, ReactNode } from 'react'
import { ResourceContext } from 'shared/contexts'

// TODO: refactor

const FlightProvider: FC<IProps> = (p) => {
	const { children } = p

	const deleteFlightHandler = () => {}

	return (
		<ResourceContext.Provider
			value={{
				resource: 'flight',
				onDelete: deleteFlightHandler,
			}}
		>
			{children}
		</ResourceContext.Provider>
	)
}

interface IProps {
	children: ReactNode
}
export default FlightProvider
