import { FC, memo } from 'react'
import { useRecordContext } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import styles from './styles.module.scss'
import { UserIcon } from '@heroicons/react/24/outline'

// TODO: refactor

const CellImg: FC<IProps> = memo(() => {
	const tableRow = useRecordContext()
	if (!tableRow?.headItem) return null
	const src = getPropertyValue(tableRow?.row, tableRow?.headItem.field)

	return (
		<>
			{src ? (
				<img className={styles.img} src={src} alt="alt" loading="lazy" />
			) : (
				<div className={[styles.img, styles.placeholder].join(' ')}>
					<UserIcon />
				</div>
			)}
		</>
	)
})

interface IProps {}

CellImg.displayName = 'CellImg'
export default CellImg
