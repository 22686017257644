import { useGetMeasureLengthQuery } from 'entities/product'
import { FC } from 'react'
import InputField from '../input-field'
import styles from './styles.module.scss'

const SelectMeasureLength: FC<IProps> = (p) => {
	const { error, label } = p
	const { data: measureLengthData } = useGetMeasureLengthQuery({})

	const measureLength = measureLengthData?.results ?? []

	return (
		<InputField error={error?.message} label={label}>
			<select className={styles.selectMeasureLength}>
				{measureLength.map((item: any) => (
					<option key={item.id} value={item.name}>
						{item.name}
					</option>
				))}
			</select>
		</InputField>
	)
}

interface IProps {
	label: string
	error: any
}
export default SelectMeasureLength
