import { ChangeEvent, FC, memo, useCallback, useMemo } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import styles from './styles.module.scss'
import { IPagination } from '../table/types'

// TODO: refactor

const Pagination: FC<IProps> = memo((p) => {
	const { pagination, setPagination, isFetching } = p

	const pers = [5, 10, 25, 50]

	const minPage = 1
	const maxPage = pagination.total_pages
	const curPage = pagination.current_page

	const isPrevDisabled = isFetching ? true : curPage <= minPage
	const isNextDisabled = isFetching ? true : curPage >= maxPage

	const allPer = pagination.total_entries
	const minPer = pagination.per_page * curPage - pagination.per_page
	const maxPer = isNextDisabled ? allPer : pagination.per_page * curPage

	const createPageButtons = Array.from(Array(maxPage + 1).keys()).slice(1)
	const showButtons = 5
	const pageButtons = useMemo(() => {
		let start = 0
		let end = showButtons

		if (curPage >= createPageButtons[showButtons - 1]) {
			start++
			end++
		}

		return createPageButtons.slice(start, end)
	}, [createPageButtons])

	const nextPage = () => {
		if (isNextDisabled) return
		const newPage = curPage + 1
		setPagination({ ...pagination, current_page: newPage })
	}
	const prevPage = () => {
		if (isPrevDisabled) return
		const newPage = curPage - 1
		setPagination({ ...pagination, current_page: newPage })
	}

	const onChangePerPage = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = +event.target.value
		setPagination({ ...pagination, per_page: value })
	}

	const setPage = (page: number) => {
		if (page < 0 || page > maxPage) {
			throw new Error('page_out_of_boundaries')
		}
		setPagination({ ...pagination, current_page: page })
	}

	return (
		<div className={styles['paginations-block']}>
			<div className={styles.result}>
				<select
					disabled={isFetching}
					onChange={onChangePerPage}
					value={pagination.per_page}
					className={styles.select}
				>
					{pers.map((item) => (
						<option key={item} value={item}>
							Количество - {item}
						</option>
					))}
				</select>
				{minPer} - {maxPer} из {pagination.total_entries}
			</div>
			<div className={styles.paginations}>
				<button disabled={isPrevDisabled} onClick={prevPage} className={[styles.button].join(' ')}>
					<ChevronLeftIcon className={styles.icon} />
				</button>
				{pageButtons.map((item) => (
					<button
						onClick={() => setPage(item)}
						key={item}
						disabled={isFetching ? true : item === curPage}
						className={[styles.button, item === curPage && styles['button-active']].join(' ')}
					>
						{item}
					</button>
				))}

				<button disabled={isNextDisabled} onClick={nextPage} className={[styles.button].join(' ')}>
					<ChevronRightIcon className={styles.icon} />
				</button>
			</div>
		</div>
	)
})

interface IProps {
	pagination: IPagination
	isFetching: boolean
	setPagination: (pagination: IPagination) => void
}

Pagination.displayName = 'Pagination'
export default Pagination
