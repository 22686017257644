import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { SellerShowProduct, useGetSellerQuery } from 'entities/seller'
import { SellerProvider } from 'features/seller'
import { ButtonBack, ButtonDelete, ButtonEdit, ButtonList, CellDeliveryZone, CellUser, LabelRow } from 'shared/components'
import { RecordContext } from 'shared/contexts'
import styles from './styles.module.scss'
import { genId } from 'shared/lib'

import moment from 'moment'

import { FeedBackProvider, FeedBackStatus } from '../../../features/feedback'
import { FeedBackInput, FeedBackMessage, useGetFeedBackQuery } from '../../../entities/feedback'
import { getUser } from 'entities/user'

import { useTypedSelector } from 'shared/hooks'

const FeedBackShowPage: FC<IProps> = () => {
	const user = useTypedSelector(getUser)
	const { feedbackId } = useParams()
	const { data: feedbackData, isLoading } = useGetFeedBackQuery(feedbackId)

	if (isLoading)
		return (
			<div className={styles.container}>
				<div>ЗАГРУЗКА...</div>
			</div>
		)

	const feedback = feedbackData

	return (
		<FeedBackProvider>
			<RecordContext.Provider
				value={{
					row: feedback,
					headItem: {
						id: genId(),
						label: '1',
						field: '1',
						sort_field: '1',
						isSort: false,
						className: '1',
						render: () => <></>,
					},
				}}
			>
				<div className={styles.page}>
					<div className={styles.header}>
						<div className={styles.container}>
							<div className={styles['header-main']}>
								<div className={styles['header-left']}>
									<ButtonBack to="/seller" />
									<div className={styles.info}>
										<LabelRow fieldClassName={styles['label-field']} label="Почта" content={feedback.email} />
										<LabelRow fieldClassName={styles['label-field']} label="Дата создания" content={moment(feedback.created_at).format('LLL')} />
									</div>
								</div>
								<div className={styles['header-right']}>
									<FeedBackStatus />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.main}>
						<div className={styles.container}>
							<div className={styles.content}>
								<div className={styles['feedback-chat']}>
									<FeedBackMessage
										message={{
											user: {
												first_name: 'Пользователь',
												id: 2,
												last_name: '',
											},

											text: feedback.user_comment,
										}}
									/>

									{feedback.admin_comment && (
										<FeedBackMessage
											message={{
												user: {
													first_name: 'Админ',
													id: 1,
													last_name: '',
												},
												main: true,
												text: feedback.admin_comment,
											}}
										/>
									)}
									{!feedback.admin_comment && <FeedBackInput />}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						<div className={styles.container}>
							<div className={styles['footer-main']}></div>
						</div>
					</div>
				</div>
			</RecordContext.Provider>
		</FeedBackProvider>
	)
}

interface IProps {}

export default FeedBackShowPage
