import {getUserName} from 'entities/user/lib/index'
import {FC} from 'react'
import Avatar from '../avatar'
import styles from './styles.module.scss'
import {CellEmpty} from 'shared/components'

const UserView: FC<IProps> = (p) => {
    const {user, email} = p

    const userName = getUserName(user)

    return (
        <div className={styles.user}>
            {user ? (
                <>
                    <Avatar src={user?.photo} alt={userName[0]}/>
                    <div className={styles.info}>
                      <span className={styles.name}>       {userName}</span>
                        {email &&
                            <p className={styles.email}>{user.email}</p>}

                    </div>
                </>
            ) : (
                <CellEmpty/>
            )}
        </div>
    )
}

interface IProps {
    user: any
  email?:boolean
}

export default UserView
