import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'

import { prepareHeaders } from '../config'

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const userAPI = createApi({
	reducerPath: 'user-api',
	tagTypes: ['User'],
	baseQuery,
	endpoints: (build) => ({
		getUser: build.query<any, any>({
			query: (id) => ({
				url: `/accounts/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['User'],
		}),
		getUsers: build.query<any, any>({
			query: (params) => ({
				url: `/accounts/`,
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['User'],
		}),
	}),
})

export const { useGetUserQuery, useGetUsersQuery } = userAPI
