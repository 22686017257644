import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const droneAPI = createApi({
	reducerPath: 'drone-api',
	tagTypes: ['Drones', 'Drone'],
	baseQuery,
	endpoints: (build) => ({
		getDrones: build.query<any, any>({
			query: (params) => ({
				url: '/drones/',
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Drones'],
		}),
		getDrone: build.query<any, any>({
			query: (droneId) => ({
				url: `/drones/${droneId}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['Drone'],
		}),
		createDrone: build.mutation<any, any>({
			query: (body) => ({
				method: 'POST',
				url: '/drones/',
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Drones'],
		}),
		eidtDrone: build.mutation<any, any>({
			query: ({ id, body }) => ({
				method: 'PATCH',
				url: `/drones/${id}/`,
				body,
				params: {
                    as: 'admin',
				},
			}),
			invalidatesTags: () => ['Drones'],
		}),
		deleteDrone: build.mutation<any, any>({
			query: (id) => ({
				method: 'DELETE',
				url: `/drones/${id}`,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Drones'],
		}),
	}),
})

export const { useGetDroneQuery, useGetDronesQuery, useCreateDroneMutation, useDeleteDroneMutation, useEidtDroneMutation } = droneAPI
