export interface IMeta {
	per_page: number
	current_page: number
	total_entries: number
	total_pages: number
}

export interface IHeadListItem {
	id: Id
	label: string
	field: string
	sort_field?: string
	isSort: boolean
	className: string
	render: () => JSX.Element
}

export interface IHead {
	id: Id
	headClassName: string
	rowClick?: string
	resource: string
	bodyClassName: string
	list: IHeadListItem[]
}
export enum ESort {
	ASC = 'asc',
	DESC = 'desc',
}
export interface IPagination extends IMeta {}
export interface IFilter {
	field?: string
	sort?: ESort
	pagination: IPagination
	fields?: {
		[key: string]: any
	}
}
