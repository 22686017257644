import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL } from 'shared/config'
import { prepareHeaders } from 'shared/services/config'

// TODO: refactor

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders,
})

export const orderAPI = createApi({
	reducerPath: 'order-api',
	tagTypes: ['Orders', 'Order'],
	baseQuery,
	endpoints: (build) => ({
		getOrders: build.query<any, any>({
			query: (params) => ({
				url: '/orders/',
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Orders'],
		}),
		getOrder: build.query<any, any>({
			query: (id) => ({
				url: `/orders/${id}/`,
				params: {
					as: 'admin',
				},
			}),
			providesTags: () => ['Order'],
		}),
		orderStatus: build.mutation<any, any>({
			query: ({ id, body }) => ({
				method: 'PATCH',
				url: `/orders/${id}/status/`,
				body,
				params: {
                    as: 'admin',
				},
			}),
			invalidatesTags: () => ['Orders', 'Order'],
		}),
		paymentOrder: build.mutation<any, any>({
			query: ({ id, body }) => ({
				method: 'PATCH',
				url: `/payments/${id}/`,
				body,
				params: {
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Orders', 'Order'],
		}),
		flightSimulation: build.mutation<any, any>({
			query: (params) => ({
				method: 'POST',
				url: `/flight-simulation/`,

				params: {
					...params,
					as: 'admin',
				},
			}),
			invalidatesTags: () => ['Order', 'Orders'],
		}),
		flightHistory: build.query<any, any>({
			query: (params) => ({
				url: `/flights-history/`,
				params: {
					...params,
					as: 'admin',
				},
			}),
			providesTags: () => ['Order'],
		}),
	}),
})

export const { useFlightHistoryQuery, useFlightSimulationMutation, useGetOrdersQuery, useGetOrderQuery, useOrderStatusMutation, usePaymentOrderMutation } = orderAPI
