import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import styles from './styles.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import {useActions, useResult} from 'shared/hooks'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import { upload } from 'shared/services/file'
import Upload from 'shared/components/upload'
import { toast } from 'react-hot-toast'

// TODO: refactor

interface FormData {
	name: string
	description: string
	product_group: string
	photo: any
}

const schema = yup
	.object({
		name: yup.string().required('Введите название'),
		photo: yup.mixed().required('Загрузите фото'),
		description: yup.string().required('Введите описание'),
	})
	.required()

const ProductCreateForm: FC<IProps> = (p) => {
	const { result, product, defaultValues, buttonLabel } = p

	const navigate = useNavigate()
	const { setLoading } = useActions()
	const { state } = useLocation()

	const {
		register,
		control,
		handleSubmit,
		setFocus,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			...defaultValues,
		},
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		setFocus('name')
	}, [])

	const successHandler = () => {
		navigate(state?.prev)
		setLoading(false)
	}

  useResult({success:successHandler,result})


	const onSubmit = async (data: FormData) => {
		try {
			setLoading(true)

			const isUrl = typeof data.photo === 'string'

			const photo = isUrl ? undefined : data.photo ? await upload(data.photo, 'image') : undefined

			const productBody = {
				...data,
				photo_id: photo?.id ?? undefined,
				product_group: state?.product_group,
			}

			await product(productBody)
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
			<div className={styles.row}>
				<div className={[styles.row, styles.flexLeft].join(' ')}>
					<Controller control={control} name="photo" render={({ field: { onChange, value } }) => <Upload error={errors?.photo} onChange={onChange} value={value} />} />
				</div>
				<div className={[styles.col, styles.flexRight].join(' ')}>
					<div className={styles.row}>
						<Input label="Название" error={errors?.name} {...register('name')} placeholder="Введите название продукта" />
					</div>
					<div className={styles.row}>
						<Input label="Описание" error={errors?.description} {...register('description')} as="textarea" placeholder="Описание продукта" />
					</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={[styles.row, styles.flexLeft].join(' ')}></div>
				<div className={[styles.col, styles.flexRight].join(' ')}>
					<div className={styles.row}>
						<Button type="submit">{buttonLabel}</Button>
					</div>
				</div>
			</div>
		</form>
	)
}

interface IProps {
	result: any
	product: any
	buttonLabel: any
	defaultValues?: any
}
export default ProductCreateForm
