import { FC, memo, ReactNode } from 'react'
import styles from './styles.module.scss'

const ButtonIcon: FC<IProps> = memo((p) => {
	const { children, className, onClick } = p
	const classNames = [styles.buttonIcon, className].join(' ')

	return (
		<button type="button" onClick={onClick} className={classNames}>
			{children}
		</button>
	)
})

interface IProps {
	children: ReactNode
	className?: string
	onClick?: () => void
}

ButtonIcon.displayName = 'ButtonIcon'
export default ButtonIcon
