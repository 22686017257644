import { KeyclockButton } from 'features/auth-keycloak'
import { FC } from 'react'
import { Logo } from 'shared/components'
import KeyclockAuth from './auth.svg'
import styles from './styles.module.scss'

const AuthKeyclockPage: FC<IProps> = () => {
	return (
		<div className={styles.auth}>
			<div className={styles.content}>
				<div className={styles.left}>
					<div className={styles.logos}>
						<Logo />
					</div>
					<div className={styles.info}>
						<div className={styles.title}>Добро пожаловать</div>
						<div>
							<KeyclockButton />
						</div>
					</div>
				</div>
				<div className={styles.right}>
					<img src={KeyclockAuth} alt="keyclock-auth" />
				</div>
			</div>
		</div>
	)
}

interface IProps {}
export default AuthKeyclockPage
