import { FC, memo, useState } from 'react'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { Avatar } from 'shared/components'
import { useAuthContext, useTypedSelector } from 'shared/hooks'
import { getUserAvatar, getUserName } from 'entities/user'
import styles from './styles.module.scss'

// TODO: попробовать вынести логику open

const UserMenu: FC<IProps> = memo(() => {
	const userName = useTypedSelector(getUserName)
	const userAvatar = useTypedSelector(getUserAvatar)
	const auth = useAuthContext()

	const [isOpen, setIsOpen] = useState(false)

	const mouseOverHandler = () => {
		if (isOpen) return
		setIsOpen(true)
	}
	const mouseLeaveHandler = () => {
		if (!isOpen) return
		setIsOpen(false)
	}

	const logout = () => auth?.logout()

	return (
		<div onMouseLeave={mouseLeaveHandler} className={styles.user}>
			<div className={[styles['user-content'], isOpen && styles.open].join(' ')}>
				<div onMouseOver={mouseOverHandler} className={styles['user-button']}>
					<Avatar src={userAvatar} className={styles.avatar} alt={'A'} />
					<div className={styles['user-info']}>
						<h2 className={styles.name}>{userName}</h2>
						<div>
							<div className={styles.role}>Админ</div>
						</div>
					</div>
				</div>

				<div className={styles.menu}>
					<div onClick={logout} className={styles.item}>
						<ArrowRightOnRectangleIcon className={styles.icon} />
						Выйти
					</div>
				</div>
			</div>
		</div>
	)
})

interface IProps {}

UserMenu.displayName = 'UserMenu'
export default UserMenu
