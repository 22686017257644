import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import {FeedBackTable, FeedBackProvider} from "features/feedback";

const FeedBackListPage: React.FC<IProps> = () => {
    return (
        <FeedBackProvider>
            <LayoutPage
                classNameMain={styles.main}
              label="Обратная связь"
                main={<FeedBackTable/>}
                right={<>{/* <ButtonCreate /> */}</>}
            />
        </FeedBackProvider>
    )
}

interface IProps {
}

export default FeedBackListPage
