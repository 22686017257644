import { Navigate, useRoutes } from 'react-router-dom'
import { paths } from './config'
import { ProductCreatePage, ProductEditPage, ProductProfileCreatePage, ProductProfileEditPage, ProductProfileShowPage, ProductShowPage } from 'pages/product'
import { SellerCreatePage, SellerEditPage, SellerListPage, SellerProductGroupCreatePage, SellerProductGroupEditPage, SellerShowPage } from 'pages/seller'
import Layout from 'widgets/layout'
import { OrderListPage, OrderShowPage } from 'pages/order'
import { CustomerListPage, CustomerShowPage } from 'pages/customer'
import { DroneCreatePage, DroneEditPage, DroneListPage } from 'pages/drone'
import { DeliveryZoneListPage } from 'pages/delivery-zone'
import { FlightListPage } from 'pages/flight'
import DeliveryZoneCreatePage from 'pages/delivery-zone/delivery-zone-create'
import { FeedBackListPage, FeedBackShowPage } from 'pages/feedback'
import { ErrorBoundary } from 'shared/components'

const AppRoutes: React.FC<IProps> = () => {
	const app = useRoutes([
		{
			path: paths.PRODUCT_CREATE,
			element: <ProductCreatePage />,
		},
		{
			path: paths.PRODUCT_SHOW,
			element: <ProductShowPage />,
		},
		{
			path: paths.PRODUCT_EDIT,
			element: <ProductEditPage />,
		},
		{
			path: paths.PRODUCT_PROFILE_CREATE,
			element: <ProductProfileCreatePage />,
		},
		{
			path: paths.PRODUCT_PROFILE_EDIT,
			element: <ProductProfileEditPage />,
		},
		{
			path: paths.PRODUCT_PROFILE_SHOW,
			element: <ProductProfileShowPage />,
		},
		{
			path: paths.SELLERS_LIST,
			element: <SellerListPage />,
		},
		{
			path: `${paths.SELLERS_SHOW}/*`,
			element: <SellerShowPage />,
		},
		{
			path: paths.SELLERS_CREATE,
			element: <SellerCreatePage />,
		},
		{
			path: paths.SELLERS_EDIT,
			element: <SellerEditPage />,
		},
		{
			path: paths.SELLERS_SHOW,
			element: <SellerShowPage />,
		},
		{
			path: paths.SELLER_GROUP_CREATE,
			element: <SellerProductGroupCreatePage />,
		},
		{
			path: paths.SELLER_GROUP_EDIT,
			element: <SellerProductGroupEditPage />,
		},

		{
			path: paths.ORDER_LIST,
			element: <OrderListPage />,
		},
		{
			path: paths.ORDER_SHOW,
			element: <OrderShowPage />,
		},

		{
			path: paths.CUSTOMER_LIST,
			element: <CustomerListPage />,
		},
		{
			path: paths.CUSTOMER_SHOW,
			element: <CustomerShowPage />,
		},

		{
			path: paths.DRONE_LIST,
			element: <DroneListPage />,
		},

		{
			path: paths.DRONE_CREATE,
			element: <DroneCreatePage />,
		},
		{
			path: paths.DRONE_EDIT,
			element: <DroneEditPage />,
		},
		{
			path: paths.DELIVERY_ZONE_LIST,
			element: <DeliveryZoneListPage />,
		},
		{
			path: paths.DELIVERY_ZONE_CREATE,
			element: <DeliveryZoneCreatePage />,
		},

		{
			path: paths.FLIGHT_LIST,
			element: <FlightListPage />,
		},
		{
			path: paths.FEEDBACK_LIST,
			element: <FeedBackListPage />,
		},
		{
			path: paths.FEEDBACK_SHOW,
			element: <FeedBackShowPage />,
		},
		{
			path: '*',
			element: <Navigate to={paths.ORDER_LIST} replace />,
		},
	])

	return (
		<Layout>
			<ErrorBoundary>{app}</ErrorBoundary>
		</Layout>
	)
}

interface IProps {}

export default AppRoutes
