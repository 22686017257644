import { getStatuses, useOrderStatusMutation } from 'entities/order'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CellPathname } from 'shared/components'
import {useActions, useRecordContext, useResult} from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import styles from './styles.module.scss'

const CellOrderStatus: React.FC<IProps> = () => {
	const statuses: any[] = useSelector(getStatuses)
	const tableRow = useRecordContext()
	const { setLoading } = useActions()

	const [orderStatus, result] = useOrderStatusMutation()
  useResult({result})

	const title = getPropertyValue(tableRow?.row, 'status')
	const id = getPropertyValue(tableRow?.row, 'id')

	const color = statuses.find((status: any) => status.label === title)?.color

	const onChangeOrderStatus = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		try {
			setLoading(true)
			const value = event.target.value
			await orderStatus({ id: id, body: { status: value } }).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<CellPathname>
			<select
				onChange={onChangeOrderStatus}
				value={title}
				className={[styles.select, color].join(' ')}
			>
				{statuses.map((status: any) => (
					<option key={status.id} value={status.label}>
						{status.name}
					</option>
				))}
			</select>
		</CellPathname>
	)
}

interface IProps {}
export default CellOrderStatus
