import { FC, ReactNode } from 'react'
import Header from 'widgets/header'

// TODO: создать файл со стилями

const Layout: FC<IProps> = (p) => {
	const { children } = p
	return (
		<>
			<Header />
			<main className="flex-1 flex flex-col">{children}</main>
		</>
	)
}

interface IProps {
	children: ReactNode
}
export default Layout
