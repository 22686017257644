/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from 'shared/hooks'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { paths } from 'app/routes'
// TODO: refactor

export const sellerProductGroupCreateFormSchema = yup
	.object({
		name: yup.string().required('Введите название '),
	})
	.required()

interface SellerForm {
	name: string
}

const SellerProductGroupForm: FC<IProps> = (p) => {
	const { result, productGroup, defaultValues } = p

	const navigate = useNavigate()
	const { setLoading } = useActions()
	const { sellerId, groupId } = useParams()

	const {
		register,
		handleSubmit,
		formState: { errors },
		setFocus,
	} = useForm<SellerForm>({
		defaultValues: {
			...defaultValues,
		},
		mode: 'onBlur',
		resolver: yupResolver(sellerProductGroupCreateFormSchema),
	})

	useEffect(() => {
		setFocus('name')
	}, [])

	const successHandler = () => {
		navigate(paths.SELLERS_SHOW.replace(':sellerId', sellerId ?? ''))
		setLoading(false)
	}
	const errorHandler = () => {
		setLoading(false)
	}

	useEffect(() => {
		if (result.isError) return errorHandler()
		if (result.isSuccess) return successHandler()
	}, [result])

	const onSubmit = async (data: SellerForm) => {
		try {
			setLoading(true)

			await productGroup({
				sellerId,
				groupId,
				...data,
			})
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
			<div className={styles.row}>
				<Input label="Название" error={errors?.name} {...register('name')} placeholder="Введите название продукта" />
			</div>

			<div className={styles.row}>
				<Button type="submit">Создать</Button>
			</div>
		</form>
	)
}

interface IProps {
	result: any
	productGroup: any
	defaultValues?: any
}
export default SellerProductGroupForm
