import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { API_URL_BASE } from 'shared/config'
import { ILoginData, ILoginFormData } from './types'

// TODO: refactor

const headers = new Headers()
headers.append('X-CSRFTOKEN', 'eXOS9hTdQ7KIWUXZaVgp25wy7c5sKqKPPT0BdDxpZL4I7NvRGNru7PQLu1DEjZFi')
headers.append('Content-Type', 'application/json')
headers.append('accept', 'application/json')

export const authAPI = createApi({
	reducerPath: 'auth-api',
	tagTypes: ['Auth'],
	baseQuery: fetchBaseQuery({ baseUrl: API_URL_BASE }),
	endpoints: (build) => ({
		login: build.mutation<ILoginData, ILoginFormData>({
			query: (body) => ({
				method: 'POST',
				url: 'auth/jwt/create',
				body,
				headers,
			}),
		}),
	}),
})

export const { useLoginMutation } = authAPI
