import { createContext } from 'react'
import { IHead } from 'shared/components/table/types'

interface IProps {
	list: any
	head: IHead
}

const TableContext = createContext<IProps | null>(null)
export default TableContext
