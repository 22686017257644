import Keycloak from 'keycloak-js'
import { KEYCLOAK_CLIENTID, KEYCLOAK_REALM, KEYCLOAK_URL } from 'shared/config'

const init = {
	realm: KEYCLOAK_REALM,
	url: KEYCLOAK_URL,
	'ssl-required': 'external',
	'public-client': true,
	flow: 'standard',
	clientId: KEYCLOAK_CLIENTID,
}

export const keycloak = new Keycloak(init)
