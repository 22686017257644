import { createContext } from 'react'
import { IHead, IHeadListItem } from 'shared/components/table/types'

interface IProps {
	row: any
	headItem?: IHeadListItem
	head?: IHead
}

const RecordContext = createContext<IProps | null>(null)
export default RecordContext
