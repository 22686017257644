import { env } from './env'

export * from './roles'
export * from './env'

export const API_URL_BASE = env.REACT_APP_BACKEND_URI
export const API_URL = `${API_URL_BASE}/api/v1`
export const KEYCLOAK_URL = env.REACT_APP_KEYCLOAK_URI
export const KEYCLOAK_REALM = `ucp`
export const KEYCLOAK_CLIENTID = `base`

export const TOKEN_KEY = 'aga'
export const AUTH_TYPE_KEY = 'auth-type'
export const THEME_KEY = 'theme'
