import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonBack, ButtonDelete, ButtonEdit, ButtonList, CellSeller, LabelRow } from 'shared/components'
import { RecordContext } from 'shared/contexts'
import styles from './styles.module.scss'
import { useGetProductQuery } from 'entities/product'
import { ProductProfileTable, ProductProvider } from 'features/product'
import { useGetSellerProductGroupQuery } from 'entities/seller'

const ProductShowPage: FC<IProps> = () => {
	const { productId } = useParams()
	const { data: productData, isLoading } = useGetProductQuery(productId)

	if (isLoading)
		return (
			<div className={styles.container}>
				<div>ЗАГРУЗКА ПРОДУКТА...</div>
			</div>
		)

	const product = productData

	return (
		<ProductProvider>
			<RecordContext.Provider value={{ row: product }}>
				<div className={styles.page}>
					<div className={styles.header}>
						<div className={styles.container}>
							<div className={styles['header-main']}>
								<div className={styles['header-left']}>
									<div className={styles['header-info']}>
										<ButtonBack />
									</div>
									<div className={styles.info}>
										<div className={styles['info-left']}>
											<img alt={product.name} className={styles.img} src={product.photo} />
										</div>

										<div className={styles['info-right']}>
											<LabelRow fieldClassName={styles['label-field']} label="Категория" content={product.category} />
											<LabelRow fieldClassName={styles['label-field']} label="Название" content={product.name} />
											<LabelRow rowClassName={styles['label-row']} fieldClassName={styles['label-field']} label="Описание" content={product.description} />
										</div>
									</div>
								</div>
								<div className={styles['header-right']}>
									<ButtonEdit />
									<ButtonList />
									<ButtonDelete />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.main}>
						<div className={styles.container}>
							<div className={styles.content}>
								<ProductProfileTable />
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						<div className={styles.container}>
							<div className={styles['footer-main']}></div>
						</div>
					</div>
				</div>
			</RecordContext.Provider>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductShowPage
