import { TOKEN_KEY } from 'shared/config'

export const prepareHeaders = (headers: any) => {
	const token = document.cookie
		.split('; ')
		.find((row) => row.startsWith(`${TOKEN_KEY}=`))
		?.split('=')[1]

	if (token) {
		headers.append('Authorization', `Bearer ${token.replace(/"/g, '')}`)
	}
	// headers.append('Access-Control-Allow-Origin', '*');
	headers.append('accept', 'application/json')
	headers.append('Content-Type', 'application/json')
	headers.append('accept-language', 'ru')

	return headers
}
