import { useFlightLandingPermitMutation } from 'entities/flight'
import { CellPathname } from 'shared/components'
import { useActions, useRecordContext,useResult } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import styles from './styles.module.scss'

const statuses = [
	{
		id: 1,
		value: true,
		name: 'Разрешено',
		color: 'bg-green-600/5 dark:bg-green-600/10 text-green-600 border border-green-600/20 dark:border-green-600/20',
	},
	{
		id: 1,
		value: false,
		name: 'Неразрешено',
		color: 'bg-red-600/5 dark:bg-red-600/10 text-red-600 border border-red-600/20 dark:border-red-600/20',
	},
]

const CellFlightLandingPermit: React.FC<IProps> = (p) => {
    const {field}=p
	const tableRow = useRecordContext()
	const { setLoading } = useActions()
	const [flightLandingPermit, result] = useFlightLandingPermitMutation()
  useResult({result})



	if (!tableRow?.headItem) return null

  const flight = getPropertyValue(tableRow?.row, field ?? tableRow?.headItem.field)

  console.log('flight',flight)
  if (!flight) return null

  const isLandingPermit = getPropertyValue(flight, 'landing_permit')
  const flightId = getPropertyValue(flight, 'id')

	const color = statuses?.find((paidStatus) => paidStatus?.value === isLandingPermit)?.color

	const onChangeFlightLandingPermitStatus = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		try {
			setLoading(true)
			const value = event.target.value
			await flightLandingPermit({ id: flightId, body: { landing_permit: value } }).unwrap()
		} catch (error) {
			setLoading(false)
		}
	}



	return (
		<CellPathname>
			<select onChange={onChangeFlightLandingPermitStatus} value={isLandingPermit} className={[styles.select, color].join(' ')}>
				{statuses.map((status: any) => (
					<option key={status.id} value={status.value}>
						{status.name}
					</option>
				))}
			</select>
		</CellPathname>
	)
}

interface IProps {
    field?:string
}
export default CellFlightLandingPermit
