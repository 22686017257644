/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useActions } from 'shared/hooks'

import { yupResolver } from '@hookform/resolvers/yup'

import { paths } from 'app/routes'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import styles from './styles.module.scss'
import * as yup from 'yup'

// TODO: refactor

export const schema = yup
	.object({
		title: yup.string().required('Введите название'),
		address: yup.string().required('Введите адрес'),
		latitude: yup.mixed().required('Введите широту'),
		longitude: yup.mixed().required('Введите долготу'),
	})
	.required()

interface SellerForm {
	title: string
	address: string
	latitude: number
	longitude: number
}

const DeliveryZoneForm: FC<IProps> = (p) => {
	const { deliveryZone, result, defaultValues, buttonLabel } = p
	const navigate = useNavigate()
	const { setLoading } = useActions()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SellerForm>({
		defaultValues: {
			title: undefined,
			address: undefined,
			latitude: undefined,
			longitude: undefined,
			...defaultValues,
		},
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

	const successHandler = () => {
		setLoading(false)
		navigate(paths.DELIVERY_ZONE_LIST)
	}
	const errorHandler = () => {
		setLoading(false)
	}

	useEffect(() => {
		if (result.isError) return errorHandler()
		if (result.isSuccess) return successHandler()
	}, [result])

	const onSubmit = async (data: SellerForm) => {
		try {
			setLoading(true)
			await deliveryZone({
				...data,
				coordinates: {
					latitude: +data.latitude,
					longitude: +data.longitude,
				},
			})
		} catch (error) {
			setLoading(false)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
			<div className={styles.row}>
				<div className={[styles.col, styles.flexRight].join(' ')}>
					<div className={styles.row}>
						<div className={styles.row}>
							<Input
								label="Название"
								error={errors?.title}
								{...register('title')}
								placeholder="Введите название"
							/>
						</div>
						<div className={styles.row}>
							<Input
								label="Адрес"
								error={errors?.address}
								{...register('address')}
								placeholder="Введите адрес"
							/>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.row}>
							<Input
								label="Широта"
								type="text"
								error={errors?.latitude}
								{...register('latitude')}
								placeholder="Введите широту"
							/>
						</div>
						<div className={styles.row}>
							<Input
								label="Долгота"
								type="text"
								error={errors?.longitude}
								{...register('longitude')}
								placeholder="Введите долготу"
							/>
						</div>
					</div>

					<div className={styles.row}>
						<Button type="submit">{buttonLabel}</Button>
					</div>
				</div>
			</div>
		</form>
	)
}

interface IProps {
	result: any
	deliveryZone: any
	defaultValues?: any
	buttonLabel: any
}
export default DeliveryZoneForm
