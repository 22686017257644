import { FC, memo } from 'react'
import { useRecordContext } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'

// TODO: refactor

const CellTitle: FC<IProps> = memo((p) => {
	const { left, field, className, render } = p

	const tableRow = useRecordContext()
	if (!tableRow?.headItem) return null
	const title = getPropertyValue(tableRow?.row, field ?? tableRow?.headItem.field)

	return (
		<div className={className}>
			{render ? (
				<>{render(tableRow?.row)}</>
			) : (
				<>
					{left}
					{title}
				</>
			)}
		</div>
	)
})

interface IProps {
	left?: any
	className?: any
	field?: string
	render?: (item?: any) => void
}

CellTitle.displayName = 'CellTitle'
export default CellTitle
