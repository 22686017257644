import { FC } from 'react'
import { UserView } from 'shared/components'
import { useRecordContext } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'

const CellUser: FC<IProps> = (p) => {
	const { field, isRow } = p
	const tableRow = useRecordContext()
	if (!tableRow?.headItem) return null
	const user = isRow ? tableRow?.row : getPropertyValue(tableRow?.row, field ?? tableRow?.headItem.field)

  return <UserView  user={user} />
}

interface IProps {
	field?: string
	isRow?: boolean
}
export default CellUser
