/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthRoutes } from 'app/routes'
import { FC, ReactNode } from 'react'
import { EAuthType } from 'entities/auth'
import { useCookies } from 'react-cookie'
import { AUTH_TYPE_KEY, TOKEN_KEY } from 'shared/config'
import { AuthContext } from 'shared/contexts'
import { keycloak } from 'shared/services'

// TODO: refactor

const AuthProvider: FC<IProps> = (p) => {
	const { children } = p

	const [cookies, setCookie, removeCookie] = useCookies([TOKEN_KEY, AUTH_TYPE_KEY])

	const isAuth = !!cookies[TOKEN_KEY] && !!cookies[AUTH_TYPE_KEY]

	if (!isAuth) return <AuthRoutes />

	const logout = () => {
		const type = cookies[AUTH_TYPE_KEY]

		if (type === EAuthType.KEYCLOCK) {
			removeCookie(TOKEN_KEY)
			keycloak.logout()
		}
		if (type === EAuthType.DEFAULT) {
			removeCookie(TOKEN_KEY)
			removeCookie(AUTH_TYPE_KEY)
		}
	}

	return <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>
}
interface IProps {
	children: ReactNode
}

export default AuthProvider
