import { FC } from 'react'
import { SellerProvider, SellerTable } from 'features/seller'
import { ButtonCreate } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const SellerListPage: FC<IProps> = () => {
	return (
		<SellerProvider>
			<LayoutPage
				classNameMain={styles.main}
				label="Рестораны"
				main={<SellerTable />}
				right={
					<>
						<ButtonCreate />
					</>
				}
			/>
		</SellerProvider>
	)
}

interface IProps {}
export default SellerListPage
