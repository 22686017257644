/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useActions} from 'shared/hooks'

import {yupResolver} from '@hookform/resolvers/yup'

import {paths} from 'app/routes'
import Input from 'shared/components/input'
import Button from 'shared/components/button'
import styles from './styles.module.scss'
import * as yup from 'yup'
import SelectDeliveryZone from 'shared/components/select-delivery-zone'

// TODO: refactor

export const schema = yup
    .object({
        serial_number: yup.string().required('Введите серийный номер'),
        registration_number: yup.string().required('Введите регистрационный номер'),
        title: yup.string().required('Введите название'),
        delivery_zone: yup.object().required('Выберите зону доставки'),
    })
    .required()

interface Form {
    serial_number: string
    registration_number: string
    title: string
    delivery_zone: any
}

const DroneForm: FC<IProps> = (p) => {
    const {drone, result, defaultValues, buttonLabel} = p
    const navigate = useNavigate()
    const {setLoading} = useActions()

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<Form>({
        defaultValues: {
            serial_number: undefined,
            delivery_zone: undefined,
            ...defaultValues,
        },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const successHandler = () => {
        setLoading(false)
        navigate(paths.DRONE_LIST)
    }
    const errorHandler = () => {
        setLoading(false)
    }

    useEffect(() => {
        if (result.isError) return errorHandler()
        if (result.isSuccess) return successHandler()
    }, [result])

    const onSubmit = async (data: Form) => {
        try {
            setLoading(true)
            await drone({
                ...data,
            })
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={[styles.create].join(' ')}>
            <div className={styles.row}>
                <div className={[styles.col, styles.flexRight].join(' ')}>
                    <div className={styles.row}>
                        <div className={styles.row}>
                            <Input
                                label="Название"
                                error={errors?.title}
                                {...register('title')}
                                placeholder="Введите название"
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                control={control}
                                name="delivery_zone"
                                render={({field: {onChange, value}}) => (
                                    <SelectDeliveryZone
                                        value={value}
                                        onChange={onChange}
                                        label="Зона доставки"
                                        placeholder="Выберите зону доставки"
                                        error={errors?.delivery_zone}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.row}>
                            <Input
                                label="Регистрационный номер"
                                error={errors?.registration_number}
                                {...register('registration_number')}
                                placeholder="Введите регистрационный номер"
                            />
                        </div>
                        <div className={styles.row}>
                            <Input
                              label="Серийный номер"
                                error={errors?.serial_number}
                                {...register('serial_number')}
                                placeholder="Введите серийный номер"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <Button type="submit">{buttonLabel}</Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

interface IProps {
    result: any
    drone: any
    defaultValues?: any
    buttonLabel: any
}

export default DroneForm
