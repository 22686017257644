import { getIsLoading } from 'app/store/interface'
import { motion } from 'framer-motion'
import { FC, memo } from 'react'
import { useTypedSelector } from 'shared/hooks'
import styles from './styles.module.scss'

// TODO: вынести логику и отрефакторить

const animate = {
	borderRadius: ['8%', '50%', '8%'],
}
const transition = { repeat: Infinity, duration: 0.6, ease: 'linear' }

const IsLoading: FC<IProps> = memo(() => {
	const isLoading = useTypedSelector(getIsLoading)

	return (
		<div className={[styles.loading, isLoading && styles.active].join(' ')}>
			<motion.div transition={transition} animate={{ rotate: [0, 180] }} className={styles.spinner}>
				<motion.div transition={transition} animate={animate} className={styles.circle} />
				<motion.div transition={transition} animate={animate} className={[styles.circle, styles.secondary].join(' ')} />
				<motion.div transition={transition} animate={animate} className={[styles.circle, styles.secondary].join(' ')} />
				<motion.div transition={transition} animate={animate} className={styles.circle} />
			</motion.div>
		</div>
	)
})

interface IProps {}

IsLoading.displayName = 'IsLoading'
export default IsLoading
