export interface RootState {
	statuses: any[]
}

export const initialState: RootState = {
	statuses: [
		{
			name: 'Готов к взлету',
			label: 'ready_for_takeoff',
			color: 'border-green-400 bg-green-500 text-white',
			color2: 'bg-green-500/10 text-green-500',
		},
		{
			name: 'В воздухе',
			label: 'in_the_air',
			color: 'border-blue-400 bg-blue-500 text-white',
			color2: 'bg-blue-500/10 text-blue-500',
		},
		{
			name: 'Готов к посадке',
			label: 'ready_to_plant',
			color: 'border-pink-400 bg-pink-500 text-white',
			color2: ' bg-pink-500/10 text-pink-50-',
		},
		{
			name: 'На земле',
			label: 'on_earth',
			color: 'border-amber-400 bg-amber-500 text-white',
			color2: 'bg-amber-500/10 text-amber-500',
		},
	],
}
