import { FC } from 'react'
import { Link, To } from 'react-router-dom'
import { useResourceContext } from 'shared/hooks'
import Button from '../button'

// TODO: add icon
// TODO: memo

const ButtonCreate: FC<IProps> = (p) => {
	const { state } = p
	const resourceContext = useResourceContext()

	const to = `/${resourceContext?.resource}/create`

	return (
		<Link to={to} state={state}>
			<Button>Создать</Button>
		</Link>
	)
}

interface IProps {
	state?: any
}
export default ButtonCreate
