import { useEffect, useState } from 'react'

const useTheme = () => {
	const [theme, setTheme] = useState<string>(localStorage.theme)
	const colorTheme = theme === 'dark' ? 'light' : 'dark'

	useEffect(() => {
		const root = window.document.documentElement
		root.classList.remove(colorTheme)
		root.classList.add(theme)

		localStorage.setItem('theme', theme)
	}, [colorTheme, setTheme, theme])

	return { colorTheme, setTheme }
}

export default useTheme
