import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { ProductForm } from 'entities/product'
import { ProductProvider } from 'features/product'
import { ButtonBack, ButtonList } from 'shared/components'
import { useGetProductQuery, useProductEditMutation } from 'entities/product'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const ProductEditPage: FC<IProps> = () => {
	const { productId } = useParams()

	const { data: productData, isLoading } = useGetProductQuery(productId)

	const [productEdit, result] = useProductEditMutation()

	const product = productData
	const defaultValues = {
		...product,
		photo: product?.photo,
	}

	const productCreateHandler = async (data: any) => {
		await productEdit({
			id: productId,
			body: {
				photo_id: data.photo_id,
				description: data.description,
				name: data.name,
				product_group: data.product_group,
			},
		}).unwrap()
	}

	return (
		<ProductProvider>
			<LayoutPage
				label="Редактирование продукта"
				classNameMain={styles.main}
				main={
					isLoading ? (
						<div>ЗАГРУЗКА ПРОДУКТА...</div>
					) : (
						<ProductForm
							buttonLabel="Сохранить"
							defaultValues={defaultValues}
							result={result}
							product={productCreateHandler}
						/>
					)
				}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductEditPage
