import { useRecordContext } from 'shared/hooks'
import { getPropertyValue } from 'shared/lib'
import Avatar from '../avatar'
import styles from './styles.module.scss'

const CellSeller: React.FC<IProps> = () => {
	const tableRow = useRecordContext()
	if (!tableRow?.headItem) return null
	const seller = getPropertyValue(tableRow?.row, tableRow?.headItem.field)

	return (
		<div className={styles.seller}>
			<Avatar className={styles.avatar} src={seller.photo} alt={seller.name} />
			<span className={styles.name}>{seller.name}</span>
		</div>
	)
}

interface IProps {}
export default CellSeller
