import { FC } from 'react'
import styles from './styles.module.scss'

const LayoutPage: FC<IProps> = (p) => {
	const { label, left, right, main, classNameMain, classNameHeader } = p

	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<div className={styles['page-main']}>
					<div className={[styles.header, classNameHeader].join(' ')}>
						<div className={styles['header-left']}>
							{left}
							<h2 className={styles.title}>{label}</h2>
						</div>
						<div className={styles['header-right']}>
							{/* <Filters /> */}
							{right}
						</div>
					</div>
					<div className={[styles.main, classNameMain].join(' ')}>{main}</div>
					<div className={styles.footer}></div>
				</div>
			</div>
		</div>
	)
}

interface IProps {
	left?: any
	right?: any
	classNameMain?: string
	classNameHeader?: string
	label?: any
	main?: any
}
export default LayoutPage
