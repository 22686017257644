import { FC } from 'react'
import { ProductProfileForm, useGetProductProfileQuery, useProductProfileEditMutation } from 'entities/product'
import { ProductProvider } from 'features/product'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'

const ProductProfileEditPage: FC<IProps> = () => {
	const { productProfileId, productId } = useParams()

	const { data: productProfileData, isLoading } = useGetProductProfileQuery(productProfileId)
	const [productProfileEdit, result] = useProductProfileEditMutation()

	const productProfileEditHandler = async (data: any) => {
		await productProfileEdit({
			id: productProfileId,
			body: {
				additional_description: data.additional_description,
				price: data.price,
				name: data.name,
				quantity_left: data.quantity_left,
				product: productId,
				measure_weight: data.measure_weight,
				measure_length: data.measure_length,
				weight: data.weight,
				size: data.size,
			},
		}).unwrap()
	}

	const productProfile = productProfileData
	const defaultValues = {
		...productProfile,
		price: +productProfile?.price?.replace(/[^.\d]/g, ''),
		size: +productProfile?.size?.replace(/[^.\d]/g, ''),
		weight: +productProfile?.weight?.replace(/[^.\d]/g, ''),
	}

	return (
		<ProductProvider productId={productId}>
			<LayoutPage
				label="Редактирование профиля продукта"
				classNameMain={styles.main}
				main={isLoading ? <div>get product profile isLoading...</div> : <ProductProfileForm defaultValues={defaultValues} buttonLabel="Сохранить" result={result} product={productProfileEditHandler} />}
				right={
					<>
						<ButtonList />
					</>
				}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</ProductProvider>
	)
}

interface IProps {}
export default ProductProfileEditPage
