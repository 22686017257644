import App from 'app'
import React from 'react'
import ReactDOM from 'react-dom/client'
import moment from 'moment'
import { ErrorBoundary } from 'shared/components'
import 'moment/locale/ru'
moment.locale('ru')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>
)
