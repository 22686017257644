import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'app/store'
import { User } from './types'

// TODO: попробовать вынести
export interface RootState {
	user: User
	users: any[]
}
export const initialState: RootState = {
	user: {} as User,
	users: [],
}

const reducers = {
	getUser: (state: RootState, action: PayloadAction<User>) => {
		state.user = action.payload
	},
	getUsers: (state: RootState, action: PayloadAction<any[]>) => {
		state.users = action.payload
	},
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers,
})

// TODO: попробовать вынести
export const getUser = (state: AppState) => state.user.user
export const getUsers = (state: AppState) => state.user.users
export const getUserName = (state: AppState) => state.user?.user?.name
export const getUserAvatar = (state: AppState) => state.user?.user?.avatar

export const userReducer = userSlice.reducer
export const userActions = userSlice.actions
