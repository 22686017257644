import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { SellerProductGroupForm, useSellerProductGroupCreateMutation } from 'entities/seller'
import { SellerProductGroupProvider } from 'features/seller'
import { ButtonBack, ButtonList } from 'shared/components'
import LayoutPage from 'widgets/layout-page'
import styles from './styles.module.scss'

const SellerProductGroupCreatePage: FC<IProps> = () => {
	const { sellerId } = useParams()
	const [productGroupCreate, result] = useSellerProductGroupCreateMutation()

	const productGroupCreateHandler = async (data: any) => {
		await productGroupCreate({ restaurant: data.sellerId, name: data.name }).unwrap()
	}

	return (
		<SellerProductGroupProvider id={sellerId}>
			<LayoutPage
				label="Новая категория"
				classNameMain={styles.main}
				main={<SellerProductGroupForm result={result} productGroup={productGroupCreateHandler} />}
				right={<>[[{/* <ButtonList />]] */}</>}
				left={
					<>
						<ButtonBack />
					</>
				}
			/>
		</SellerProductGroupProvider>
	)
}

interface IProps {}
export default SellerProductGroupCreatePage
