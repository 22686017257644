import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { interfaceReducer } from 'app/store/interface'
import { authAPI } from 'entities/auth'
import { sellerAPI } from 'entities/seller'
import { userReducer } from 'entities/user/model/slice'
import { fileAPI } from 'shared/services/file'
import { productAPI } from 'entities/product/api/service'

import { userAPI } from 'shared/services/user'
import { orderAPI } from 'entities/order/api'
import { deliveryZoneAPI } from 'entities/delivery-zone'
import { orderReducer } from 'entities/order'
import { customerAPI } from 'entities/customer'
import { droneAPI, droneReducer } from 'entities/drone'
import { flightAPI } from 'entities/flight'
import {feedbackAPI} from "entities/feedback";

export const setupStore = () => {
	return configureStore({
		reducer: {
			interface: interfaceReducer,
			user: userReducer,
			order: orderReducer,
			drone: droneReducer,
			[userAPI.reducerPath]: userAPI.reducer,
      [feedbackAPI.reducerPath]: feedbackAPI.reducer,
			[orderAPI.reducerPath]: orderAPI.reducer,
			[productAPI.reducerPath]: productAPI.reducer,
			[authAPI.reducerPath]: authAPI.reducer,
			[fileAPI.reducerPath]: fileAPI.reducer,
			[sellerAPI.reducerPath]: sellerAPI.reducer,
			[deliveryZoneAPI.reducerPath]: deliveryZoneAPI.reducer,
			[customerAPI.reducerPath]: customerAPI.reducer,
			[droneAPI.reducerPath]: droneAPI.reducer,
			[flightAPI.reducerPath]: flightAPI.reducer,
		},
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(),feedbackAPI.middleware, userAPI.middleware, authAPI.middleware, productAPI.middleware, sellerAPI.middleware, fileAPI.middleware, orderAPI.middleware, deliveryZoneAPI.middleware, customerAPI.middleware, droneAPI.middleware, flightAPI.middleware],
		devTools: process.env.NODE_ENV === 'development',
	})
}

export const store = setupStore()
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>
