import styles from './styles.module.scss'

const Empty: React.FC<IProps> = ({ label = 'Здесь пока что пусто' }) => {
	return <div className={styles.empty}>{label}</div>
}

interface IProps {
	label?: string
}
export default Empty
