import { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import styless from './styles.module.scss'

const Logo: FC<IProps> = memo(() => {
	return (
		<Link to="/">
			<div className={styless.logo}>
				<svg className={styless.icon} viewBox="0 0 393 76" fill="none">
					<svg width="393" height="76" viewBox="0 0 393 76" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.6107 34.08V7.79001C16.6107 3.49001 13.1207 0 8.82067 0H0.190674V36.5L16.6107 34.08Z" fill="#F8B72F" />
						<path d="M0.190674 30.2498V67.6299C0.190674 71.9299 3.68067 75.4199 7.98067 75.4199H16.6107V27.8398L0.190674 30.2498Z" fill="#F8B72F" />
						<path d="M82.6908 34.08V7.79001C82.6908 3.49001 79.2007 0 74.9007 0H66.2708V36.5L82.6908 34.08Z" fill="#F8B72F" />
						<path
							d="M270.031 38.3798V7.79974C270.031 3.49974 266.541 0.00976562 262.241 0.00976562H253.611V37.6398L270.031 38.3798Z"
							fill="#F8B72F"
						/>
						<path
							d="M321.571 4.65977L339.171 22.3098L333.831 27.6398C330.781 30.6798 325.851 30.6798 322.811 27.6298L295.481 0.249771H311.121C311.111 0.259771 316.741 -0.0302258 321.571 4.65977Z"
							fill="#F8B72F"
						/>
						<path
							d="M366.821 4.66034L336.231 35.3103L341.571 40.6404C344.621 43.6804 349.551 43.6803 352.591 40.6303L392.921 0.260349H377.281C377.291 0.260349 371.661 -0.0296574 366.821 4.66034Z"
							fill="#F8B72F"
						/>
						<path d="M290.49 59H307.78C312.08 59 315.57 62.49 315.57 66.79V75.42H290.9L290.49 59Z" fill="#F8B72F" />
						<path d="M66.2805 30.2498V67.6299C66.2805 71.9299 69.7705 75.4199 74.0705 75.4199H82.7005V27.8398L66.2805 30.2498Z" fill="#F8B72F" />
						<path d="M116.281 41.3301V67.6201C116.281 71.9201 112.791 75.4102 108.491 75.4102H99.8606V38.9102L116.281 41.3301Z" fill="#F8B72F" />
						<path d="M187.781 41.3301V67.6201C187.781 71.9201 184.291 75.4102 179.991 75.4102H171.361V38.9102L187.781 41.3301Z" fill="#F8B72F" />
						<path d="M99.8606 66.8301V29.4502C99.8606 25.1502 103.351 21.6602 107.651 21.6602H116.271V69.2502L99.8606 66.8301Z" fill="#F8B72F" />
						<path d="M14.7804 41.0801H52.1604C56.4604 41.0801 59.9504 37.5902 59.9504 33.2902V25.4102H12.3704L14.7804 41.0801Z" fill="#F8B72F" />
						<path d="M139.531 45.7501H152.571C156.871 45.7501 160.361 42.2601 160.361 37.9601V30.0801H137.111L139.531 45.7501Z" fill="#F8B72F" />
						<path d="M141.691 30.0801H128.651C124.351 30.0801 120.861 33.5701 120.861 37.8701V45.7501H144.111L141.691 30.0801Z" fill="#F8B72F" />
						<path d="M223.781 15.67H236.821C241.121 15.67 244.611 12.18 244.611 7.88V0H221.361L223.781 15.67Z" fill="#F8B72F" />
						<path d="M225.571 0H179.151C174.851 0 171.361 3.49001 171.361 7.79001V15.67H227.991L225.571 0Z" fill="#F8B72F" />
						<path d="M116.28 15.67V0H107.66C103.36 0 99.8704 3.49001 99.8704 7.79001V15.67H116.28Z" fill="#F8B72F" />
						<path d="M171.361 41.3301V67.6201C171.361 71.9201 174.851 75.4102 179.151 75.4102H187.781V38.9102L171.361 41.3301Z" fill="#F8B72F" />
						<path d="M171.361 30.0801V45.7501H231.261C235.561 45.7501 239.051 42.2601 239.051 37.9601V30.0801H171.361Z" fill="#F8B72F" />
						<path
							d="M253.611 36.8098C253.611 58.1098 270.881 75.3698 292.171 75.3698V58.9898C279.921 58.9898 269.981 49.0598 269.981 36.7998H253.611V36.8098Z"
							fill="#F8B72F"
						/>
						<path d="M335.861 33V75.42H344.491C348.791 75.42 352.281 71.93 352.281 67.63V33H335.861Z" fill="#F8B72F" />
						<path
							d="M321.57 4.66034C316.73 -0.0296574 311.11 0.260349 311.11 0.260349H295.47L322.8 27.6404C325.84 30.6904 330.77 30.6903 333.82 27.6503L339.16 22.3203L321.57 4.66034Z"
							fill="#F8B72F"
						/>
						<path
							d="M221.361 0H179.151C174.851 0 171.361 3.49001 171.361 7.79001V15.67H223.781H227.991H236.821C241.121 15.67 244.611 12.18 244.611 7.88V0H225.571H221.361Z"
							fill="#F8B72F"
						/>
						<path d="M99.8606 7.79001V15.67H116.281V0H107.661C103.351 0 99.8606 3.49001 99.8606 7.79001Z" fill="#F8B72F" />
						<path
							d="M16.6106 67.1602C10.4006 69.0802 5.36056 70.7501 1.57056 72.0501C2.98056 74.0801 5.32056 75.4202 7.98056 75.4202H16.6106V67.1602Z"
							fill="#F8B72F"
						/>
						<path
							d="M74.0705 75.42H82.7005V50.25C76.9905 51.44 71.5205 52.65 66.2805 53.86V67.62C66.2805 71.93 69.7705 75.42 74.0705 75.42Z"
							fill="#F8B72F"
						/>
						<path
							d="M116.111 69.2302L116.281 69.2502V67.6202V43.9502C110.651 44.8902 105.181 45.8602 99.8606 46.8502V66.8302V75.4102H108.491C112.241 75.4202 115.371 72.7602 116.111 69.2302Z"
							fill="#F8B72F"
						/>
						<path
							d="M144.111 45.7496H152.571C156.871 45.7496 160.361 42.2596 160.361 37.9596V37.8096C146.521 39.3696 133.331 41.1996 120.861 43.1996V45.7496H139.531H144.111Z"
							fill="#F8B72F"
						/>
						<path
							d="M171.361 67.63V75.42H179.151H179.981H187.771V67.63V45.75H231.251C235.551 45.75 239.041 42.26 239.041 37.96V32.54C231.031 32.8 222.881 33.12 214.601 33.5C199.641 34.19 185.201 35.28 171.351 36.65V45.75V67.63H171.361Z"
							fill="#F8B72F"
						/>
						<path
							d="M307.781 58.9998H292.181C280.371 58.9998 270.711 49.7698 270.031 38.1198V31.7998C264.641 31.8798 259.161 31.9898 253.611 32.1298V37.6198H253.631C254.061 58.1198 270.461 74.6698 290.901 75.3398V75.4198H315.571V66.7898C315.571 62.4898 312.081 58.9998 307.781 58.9998Z"
							fill="#F8B72F"
						/>
						<path
							d="M339.511 32.0195L338.531 32.9995H335.861V75.4196H344.491C348.791 75.4196 352.281 71.9295 352.281 67.6295V40.9196C352.391 40.8196 352.491 40.7395 352.601 40.6295L360.631 32.5895C353.911 32.3695 346.861 32.1795 339.511 32.0195Z"
							fill="#F8B72F"
						/>
					</svg>
				</svg>
			</div>
		</Link>
	)
})

interface IProps {}

Logo.displayName = 'Logo'
export default Logo
